import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { nord } from '@uiw/codemirror-theme-nord';
import { useData } from '../contexts/DataContext';
import { useUser } from '@clerk/clerk-react';
import CinemaModal from './CinemaModal';
import { RiFullscreenLine, RiExternalLinkLine } from "react-icons/ri";
import { Loader2 } from "lucide-react";
import { Tabs, TabsList, TabsTrigger, } from "@/components/ui/tabs"
import { FaChevronRight } from 'react-icons/fa';

const CodePreview = () => {
  const [loading, setLoading] = useState(false);
  const [silentLoading, setSilentLoading] = useState(false);
  const [showCode, setShowCode] = useState(true);
  const [previewUrl, setPreviewUrl] = useState('');
  const { currentCode, setCurrentCode, isStreaming, setIsPanelVisible, setSelectedPanel, triggerPreview, setTriggerPreview } = useData();
  const { user } = useUser();
  const iframeRef = useRef(null);
  const [isCinemaMode, setIsCinemaMode] = useState(false);
  const contentContainerRef = useRef(null);

  const handleHide = () => {
    setSelectedPanel(null);
    setIsPanelVisible(false);
  };

  useEffect(() => {
    if (isStreaming && contentContainerRef.current) {
      contentContainerRef.current.scrollTop = contentContainerRef.current.scrollHeight;
    }
  }, [isStreaming, currentCode]);

  useEffect(() => {
    setShowCode(true);
  }, [currentCode]);

  useEffect(() => {
    const renderPreview = async () => {
      if (currentCode && showCode && triggerPreview) {
        await handleArtifactLoad();
        await handleToggle();
        setTriggerPreview(false);
      }
    }
    renderPreview();
  }, [isStreaming, currentCode, showCode, triggerPreview]);

  const handleArtifactLoad = async (load = true) => {
    try {
      if (load) {
        setLoading(true);
      } else {
        setSilentLoading(true);
      }
      const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/api/v1/interact/tasks/artifacts/execute`, { user_id: user.id, code: currentCode, path: '/home/user/app/page.tsx' });
      setPreviewUrl(response.data.url);
    } catch (error) {
      console.error('Error rendering code:', error);
    } finally {
      if (load) {
        setLoading(false);
      } else {
        setSilentLoading(false);
      }
    }
  };

  const handleToggle = async () => {
    if (showCode) {
      setShowCode(false);
      setLoading(true);
      const delay = silentLoading ? 2500 : 800;
      setTimeout(async () => {
        setLoading(false);
      }, delay);
    } else {
      setShowCode(true);
    }
  };

  const openInNewTab = () => {
    if (previewUrl) {
      window.open(previewUrl, '_blank');
    }
  };

  const toggleCinemaMode = () => {
    setIsCinemaMode((prev) => !prev);
  };

  const handleBlur = async () => {
    await handleArtifactLoad(false);
  };

  const onChange = useCallback((value) => {
    setCurrentCode(value);
  }, []);

  return (
    <div className="_code-preview">
      <div className="_buttons">
        {/* center it */}
        <Tabs className="flex flex-row gap-2 mb-3 justify-center w-full" value={showCode ? 'code' : 'preview'}>
          <button style={{ opacity: 0, width: "1rem" }}></button>
          <div className="flex flex-row flex-grow justify-center gap-2">
            {loading && <Loader2 className="mt-1.5 text-gray-400 animate-spin" />}
            <TabsList className="_tabs-list">
              <TabsTrigger className="_toggle-button" value="code" onClick={handleToggle} >Code</TabsTrigger>
              <TabsTrigger className="_toggle-button" value="preview" onClick={handleToggle} disabled={isStreaming}>Preview</TabsTrigger>
            </TabsList>
            {loading && <div style={{ width: "1.8rem" }}></div>}
          </div>
          <button onClick={handleHide}>
            <FaChevronRight />
          </button>
        </Tabs>
      </div>
      <div className="_content-container" ref={contentContainerRef}>
        {currentCode && (
          showCode ? (
            <CodeMirror
              value={currentCode}
              height="100%"
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
              onBlur={handleBlur}
              theme={nord}
            />
          ) : (
            <div className="_preview-container">
              {(previewUrl && !silentLoading) && (
                <>
                  <iframe
                    ref={iframeRef}
                    src={previewUrl}
                    className="_preview-iframe"
                    title="Code Preview"
                    sandbox="allow-scripts allow-same-origin"
                  />
                  <div className="_preview-buttons">
                    <button className="_open-cinema-button" onClick={toggleCinemaMode} title="Open in Cinema Mode">
                      <RiFullscreenLine />
                    </button>
                    <button className="_open-external-button" onClick={openInNewTab} title="Open in External Window">
                      <RiExternalLinkLine />
                    </button>
                  </div>
                </>
              )
              }
            </div >
          ))
        }
      </div >
      {isCinemaMode && (
        <CinemaModal url={previewUrl} onClose={toggleCinemaMode} />
      )}
    </div >
  );
};

export default CodePreview;
