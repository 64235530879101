import { useState, useMemo, useRef, useCallback, useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Loader2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { LuRefreshCw } from "react-icons/lu";
import { LuChevronDown, LuChevronRight, LuExternalLink, LuArrowBigDown, LuArrowBigUp, LuTrash2 } from "react-icons/lu";
import { LuCircle, LuMinusCircle, LuCheckCircle, LuXCircle, LuLoader2 } from "react-icons/lu";
import { AiOutlineLoading } from "react-icons/ai";

import * as Tooltip from '@radix-ui/react-tooltip';
import { useClerk } from "@clerk/clerk-react";
import { useData } from "@/contexts/DataContext";

import { toast } from "react-hot-toast";

export function LibrarySourcesCard({ drawerOpen }) {
  const columns = [
    {
      accessorKey: "file_name",
      header: "Name",
    },
    {
      accessorKey: "file_type",
      header: "Type",
    },
    {
      accessorKey: "updated_at",
      header: "Last updated",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
  ]
  const drawerColumns = [
    {
      accessorKey: "file_name",
      header: "Name",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
  ]

  const [loading, setLoading] = useState(false);
  const {
    handleItemUpdate,
    deleteDriveFolder,
    blockPriorityUpdates,
    streamFolderSyncStatus,
    refreshLibraryItem,
    libraryData,
    updateChildIngestionStatus,
  } = useData();
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [deletingItems, setDeletingItems] = useState({});
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [tempPriorityChanges, setTempPriorityChanges] = useState(0);
  const timerRef = useRef(null);
  const workingPriorityRef = useRef(0);
  const [refreshLock, setRefreshLock] = useState(false);
  const { signOut } = useClerk();
  const [flattenedData, setFlattenedData] = useState([]);
  useEffect(() => {
    setFlattenedData(libraryData.flatMap(folder => [
      folder,
      ...(expandedFolders.has(folder.id) && folder.id.startsWith('Project__') ? 
      folder.children.map(child => ({...child, id: `${folder.id}_${child.id}`})) : expandedFolders.has(folder.id) ? folder.children : [])
    ]));
  }, [libraryData, expandedFolders]);

  const handleFolderClick = (id) => {
    setExpandedFolders(prev => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const handlePriorityChange = useCallback((item, change) => {
    let newPriority;
    if (!isUpdatePending[item.id]) {
      newPriority = item.priority + change;
      workingPriorityRef.current = newPriority;
      setTempPriorityChanges(prev => ({ ...prev, [item.id]: newPriority }));
    } else {
      newPriority = Math.max(0, workingPriorityRef.current + change);
      workingPriorityRef.current = newPriority;
      setTempPriorityChanges(prev => ({ ...prev, [item.id]: newPriority }));
    }
    setIsUpdatePending(prev => ({ ...prev, [item.id]: true }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      handleItemUpdate(item.id, { priority: newPriority });
      setTempPriorityChanges({});
      workingPriorityRef.current = 0;
      setIsUpdatePending({});
    }, 2000);

  }, [isUpdatePending, handleItemUpdate]);

  const handleDelete = (id) => {
    // Placeholder function for handling delete
    setDeletingItems(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const confirmDelete = useCallback(async (id) => {
    setLoading(true);
    await deleteDriveFolder(id);
    setDeletingItems(prev => ({ ...prev, [id]: false }));
    setLoading(false);
  }, [deleteDriveFolder]);

  const handleRefresh = async (id) => {
    if (!refreshLock) {
      setRefreshLock(true);
      streamFolderSyncStatus(id);
      setTimeout(() => {
        setRefreshLock(false);
      }, 8000);
      try {
        await refreshLibraryItem(id);
      } catch (error) {
        console.log("Error refreshing library item", error);
        if (error.message.includes("api.clerk") || error.message.includes("oauth2.googleapis.com") || error.message.includes("refresh_token")) {
          const url = new URL(window.location.href).pathname;
          console.log("Redirecting to sign in page");
          await signOut({ redirectUrl: `/?redirect=${true}&from=${url}` });
        } else {
          setRefreshLock(false);
          toast.error(error.message);
        }
      }
    }
  };

  const table = useReactTable({
    data: flattenedData,
    columns: drawerOpen ? drawerColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getStatusIcon = (status, folder = false) => {
    if (!folder) {
      switch (status) {
        case 'Processing':
          return (<span className="_ellipsis-anim">
            <LuCircle className="_library-item-status-icon" />
          </span>)
        case 'ProcessingInitiated':
          return (<AiOutlineLoading className="_library-item-status-icon loading" />)
        case 'Ingested':
          return <LuCheckCircle className="_library-item-status-icon success" />;
        case 'Incompatible':
          return <LuMinusCircle className="_library-item-status-icon" />;
        case 'Failed':
          return <LuXCircle className="_library-item-status-icon error" />;
        default:
          return <LuCircle className="_library-item-status-icon" />;
      }
    } else {
      switch (status) {
        case 'Processing':
          return (<LuLoader2 className="_library-item-status-icon spinner" />)
        case 'Ingested':
          return <LuCheckCircle className="_library-item-status-icon success" />;
        default:
          return <LuCircle className="_library-item-status-icon" />;
      };
    }
  }

  return (
    <Tooltip.Provider>
      <div className={`_library-table-container ${drawerOpen ? 'drawer-open' : ''}`}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {cell.column.id === 'file_name' ? (
                        <div className={`flex items-start gap-2 ${row.original.file_type !== 'FOLDER' ? 'pl-8' : 'pl-0'} ${drawerOpen ? 'text-xs' : ''}`}>
                          {row.original.file_type === 'FOLDER' && (
                            <>
                              {expandedFolders.has(row.original.id) ?
                                <LuChevronDown className="_library-item-folder-toggle" onClick={() => handleFolderClick(row.original.id)} /> :
                                <LuChevronRight className="_library-item-folder-toggle" onClick={() => handleFolderClick(row.original.id)} />
                              }
                            </>
                          )}
                          {(
                            <Tooltip.Root>
                              <Tooltip.Trigger>
                                <div className="flex items-start pt-0.5">
                                  {getStatusIcon(row.original.ingestion_status, row.original.file_type === 'FOLDER')}
                                </div>
                              </Tooltip.Trigger>
                              <Tooltip.Content className="text-xs text-gray-500 border border-gray-200 p-1 bg-white">
                                {row.original.ingestion_status}
                              </Tooltip.Content>
                            </Tooltip.Root>
                          )}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      ) : cell.column.id === 'priority' ? (
                        <div className="pl-2 flex items-center gap-2">
                          {blockPriorityUpdates ? (<div className="text-gray-500 text-xs">-</div>) : (
                            row.original.file_type !== 'FOLDER' && !row.original.id.startsWith('Project__') && (
                              <div className='flex items-center gap-1'>
                                <button
                                  className='text-gray-500 hover:text-gray-700'
                                  onClick={() => handlePriorityChange(row.original, -1)}
                                  disabled={Object.keys(isUpdatePending).length !== 0 && !isUpdatePending[row.original.id] || ((row.original.priority || tempPriorityChanges[row.original.id] || 0) <= 0)}
                                >
                                  <LuArrowBigDown />
                                </button>
                                <span className={tempPriorityChanges[row.original.id] ? 'text-blue-500' : ''}>
                                  {(tempPriorityChanges[row.original.id] !== undefined) ? tempPriorityChanges[row.original.id] : row.original.priority}
                                </span>
                                <button
                                  className='text-gray-500 hover:text-gray-700'
                                  onClick={() => handlePriorityChange(row.original, 1)}
                                  disabled={Object.keys(isUpdatePending).length !== 0 && !isUpdatePending[row.original.id]}
                                >
                                  <LuArrowBigUp />
                                </button>
                              </div>
                            ))}
                          {row.original.file_type === 'FOLDER' && deletingItems[row.original.id] && (
                            <>
                              <div className="flex items-center text-xs text-gray-500 hover:text-gray-900 hover:underline " >
                                {loading ? <Loader2 className="ml-2 text-gray-400 animate-spin" /> : <p className="cursor-pointer" onClick={() => confirmDelete(row.original.id)}>Click to confirm</p>}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="pl-2 flex items-center gap-2">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <div className="flex items-center gap-2">
                      {row.original.file_type === 'FOLDER' && !row.original.id.startsWith('Project__') ? (
                        <>
                          <button
                            className={`${!refreshLock ? 'text-gray-500 hover:text-gray-700 hover:bg-gray-200 p-1' : 'text-gray-200 hover:text-gray-200 p-1'}`}
                            onClick={() => handleRefresh(row.original.id)}
                            title="Refresh Folder"
                          >
                            <LuRefreshCw className="w-4 h-4" />
                          </button>
                          <button
                            className={`text-gray-500 hover:text-gray-900 ${deletingItems[row.original.id] ? 'bg-gray-100 rounded-full p-1' : ''}`}
                            onClick={() => deletingItems[row.original.id] ? confirmDelete(row.original.id) : handleDelete(row.original.id)}
                            title={deletingItems[row.original.id] ? 'Confirm Delete' : 'Delete Source'}
                          >
                            <LuTrash2 />
                          </button>
                        </>
                      ) : (
                        <div className="pl-3.5"></div>
                      )}
                      {row.original.file_type === 'FOLDER' && row.original.id.startsWith('Project__') && (
                          <button
                            className={`text-gray-500 hover:text-gray-900 ${deletingItems[row.original.id] ? 'bg-gray-100 rounded-full p-1' : ''}`}
                            onClick={() => deletingItems[row.original.id] ? confirmDelete(row.original.id) : handleDelete(row.original.id)}
                            title={deletingItems[row.original.id] ? 'Confirm Delete' : 'Delete Source'}
                          >
                            <LuTrash2 />
                          </button>
                      )}
                      {row.original.url && !row.original.id.startsWith('Project__') && (
                        <a href={row.original.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                          <LuExternalLink />
                        </a>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Tooltip.Provider>
  );
}
