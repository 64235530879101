import { convertMimeType } from "@/lib/actions";
import { useAuth, useUser } from "@clerk/clerk-react";
import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import toast from "react-hot-toast";

const API_URL = `${import.meta.env.VITE_APP_API_URL}/api/v1`;

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { getToken } = useAuth();
  const { user } = useUser();
  const [projects, setProjects] = useState([]);
  const [activeProjectId, setActiveProjectId] = useState("");
  const [channels, setChannels] = useState([]);
  const [activeChannelId, setActiveChannelId] = useState("");
  const [currentCode, setCurrentCode] = useState("");
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [chatSidebarOpen, setChatSidebarOpen] = useState(false);
  const [triggerPreview, setTriggerPreview] = useState(false);
  const [slmLeaderboardData, setSlmLeaderboardData] = useState({});

  const [libraryId, setLibraryId] = useState(null);
  const [firstFolderData, setFirstFolderData] = useState({});
  const [libraryData, setLibraryData] = useState([]);
  const [blockPriorityUpdates, setBlockPriorityUpdates] = useState(false);

  const [editingChannelId, setEditingChannelId] = useState(null);
  const [editedChannelName, setEditedChannelName] = useState("");

  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);

  const toggleLeftSidebar = () => {
    setIsLeftSidebarOpen((prevState) => !prevState);
    document.body.classList.toggle("_left-sidebar-open");
  };

  const toggleRightSidebar = (force = false) => {
    if (!force) {
      setIsRightSidebarOpen((prevState) => !prevState);
      document.body.classList.toggle("_right-sidebar-open");
    } else {
      setIsRightSidebarOpen(false);
      document.body.classList.remove("_right-sidebar-open");
    }
  };

  const apiCall = async (
    method,
    urlSegment,
    headers = {},
    data = null,
    responseType = "json"
  ) => {
    try {
      const response = await axios({
        method,
        url: `${API_URL}/${urlSegment}`,
        headers: {
          ...headers,
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await getToken()}`,
        },
        data,
        responseType,
      });
      return response.data;
    } catch (error) {
      toast.error("An error occurred.");
      console.error({
        status: error.response?.status || 500,
        statusText: error.response?.statusText || "Internal Server Error",
        error: error.response?.data || error.message || "Unknown error",
      });
    }
  };

  const ProjectsAPI = {
    browseProjects: async () => {
      return await apiCall("GET", "projects/");
    },

    readProject: async (projectId) => {
      return await apiCall("GET", `projects/${projectId}`);
    },

    editProject: async (payload) => {
      const response = await apiCall(
        "PUT",
        `projects/${payload.project_id}`,
        {},
        payload
      );

      if (!response) {
        return null;
      }

      const rsp = await apiCall("GET", "projects/");
      setProjects(rsp);

      return response;
    },

    addProject: async (payload) => {
      const newProject = {
        name: payload.name,
        description: payload.description || null,
        collection: payload.collection || [],
        users: payload.users || {},
        predefined_queries: payload.predefined_queries || [],
        custom_instructions: payload.custom_instructions || '',
        messages: payload.messages || [],
        model: payload.model || null,
        comments: payload.comments || [],
      };

      const response = await apiCall("POST", "projects/", {}, newProject);

      if (!response.project_id) {
        return null;
      }

      setActiveProjectId(response.project_id);
      setActiveChannelId(null);

      const rsp = await apiCall("GET", "projects/");
      setProjects(rsp);

      return response;
    },

    deleteProject: async (projectId) => {
      return await apiCall("DELETE", `projects/${projectId}`);
    },

    addUserToProject: async (projectId, email) => {
      return await apiCall("GET", `projects/${projectId}/add-user/${email}`);
    },

    removeUserFromProject: async (projectId, userId) => {
      return await apiCall(
        "DELETE",
        `projects/${projectId}/remove-user/${userId}`
      );
    },

    completeInvitation: async (inviteId) => {
      return await apiCall("GET", `projects/invite-completed/${inviteId}`);
    },

    onboard: async () => {
      return await apiCall("POST", "projects/onboard-user");
    },

    acceptInvite: async (inviteId) => {
      return await apiCall("POST", `projects/invite/email/${inviteId}`);
    },

    joinViaLink: async (invite_link_id) => {
      return await apiCall("POST", `projects/invite/join/${invite_link_id}`);
    },

    generateGuidedQueries: async (projectId, messages) => {
      return await apiCall(
        "POST",
        `projects/${projectId}/generate_queries`,
        {},
        messages
      );
    },
  };

  const UsersAPI = {
    getUserProfile: async (userId) => {
      return await apiCall("GET", `account/public-profile/${userId}`);
    },

    searchUserProfiles: async (term) => {
      return await apiCall("GET", `account/search?search_term=${term}&limit=5`);
    },

    getGoogleDriveToken: async () => {
      const rsp = await apiCall("GET", "account/clerk/google");
      if (rsp && rsp.status === "success") {
        return rsp.oauth_token;
      } else {
        throw new Error(rsp.message);
      }
    },
  };

  const InteractAPI = {
    spawnSandbox: async () => {
      console.log("spawning sandbox");
      return await apiCall(
        "POST",
        "interact/tasks/artifacts/sandbox",
        {},
        {
          user_id: user.id,
          template: "mas-nextjs-authjs-prisma",
        }
      );
    },
  };

  const ChannelsAPI = {
    getChannels: async (projectId) => {
      const rsp = await apiCall("GET", `projects/${projectId}/channels`);

      if (!rsp) return;

      setChannels(rsp);

      return rsp;
    },

    getChannel: async (projectId, channelId) => {
      return await apiCall(
        "GET",
        `projects/${projectId}/channels/${channelId}`
      );
    },

    createChannel: async (projectId, channelCreate) => {
      const rsp = await apiCall(
        "POST",
        `projects/${projectId}/channels`,
        {},
        channelCreate
      );

      if (!rsp) return;

      const channel = {
        id: rsp.channel_id,
        name: rsp.channel_name,
        state: "private",
      };

      setChannels((prev) => [...prev, channel]);

      return channel;
    },

    getChannelMessages: async (projectId, channelId) => {
      return await apiCall(
        "GET",
        `projects/${projectId}/channels/${channelId}/messages`
      );
    },

    updateChannel: async (projectId, channelId, updates) => {
      const current = await ChannelsAPI.getChannel(projectId, channelId);

      const rsp = await apiCall(
        "PUT",
        `projects/${projectId}/channels/${channelId}`,
        {},
        { ...current, ...updates }
      );

      if (!rsp) return;

      setChannels((prevChannels) =>
        prevChannels.map((i) => (i.id === channelId ? rsp : i))
      );
      setEditingChannelId(null);
      setActiveChannelId(rsp.id);
      return rsp;
    },

    deleteChannel: async (projectId, channelId) => {
      const rsp = await apiCall(
        "DELETE",
        `projects/${projectId}/channels/${channelId}`
      );

      if (!rsp) return;

      setChannels((prev) => prev.filter((channel) => channel.id !== channelId));
      if (activeChannelId === channelId) {
        setActiveChannelId(null);
      }
    },

    forkChannel: async (projectId, channelId) => {
      return await apiCall(
        "POST",
        `projects/${projectId}/channels/${channelId}/fork`
      );
    },

    exportData: async (projectId, exportOption, exportType, channelId = null, fileName = "dump", fileExtension = "txt") => {
      const body = {
        export_option: exportOption,
        export_type: exportType,
        channel_id: channelId,
      };

      const response = await apiCall(
        "POST",
        `projects/${projectId}/export`,
        {},
        body,
        "blob"
      );

      if (response) {
        downloadBlob(response, `${fileName}.${fileExtension}`);
      }

      return response;
    },
  };

  const downloadBlob = (blobData, fileName) => {
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const AIAPI = {
    streamResponse: async (
      messageId,
      query,
      pastMessages,
      fileAttachment = null,
      fileName = null,
      lmType = "openai-4o",
      libraryId = "dfd08b55-fbd2-4013-a3f2-974b94927046",
      topK = 3,
      metadata = null,
      projectId = undefined,
      sharedContext = True,
      customInstructions = null
    ) => {
      const token = await getToken();

      const payload = {
        message_id: messageId,
        query: query,
        library_id: libraryId,
        top_k: topK,
        past_messages: pastMessages,
        file_attachment: fileAttachment,
        file_name: fileName,
        lm_type: lmType,
        metadata: metadata,
        is_image: !!fileAttachment,
        project_id: projectId,
        shared_context: sharedContext,
        custom_instructions: customInstructions
      };
      const response = await fetch(`${API_URL}/interact/stream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      return response.body.getReader();
    },
  };

  const LibraryAPI = {
    getLibrary: async (libraryId) => {
      return await apiCall("GET", `library/?library_id=${libraryId}`);
    },

    addSource: async (url, library_id) => {
      return await apiCall(
        "POST",
        `library/ingest/`,
        {},
        { drive_url: url, library_id: library_id }
      );
    },

    updateItem: async (indexReference, itemId, updates) => {
      return await apiCall(
        "PATCH",
        `library/${indexReference}/${itemId}`,
        {},
        updates
      );
    },

    deleteDriveFolder: async (library_id, index_reference) => {
      return await apiCall(
        "DELETE",
        `library/${library_id}/${index_reference}`
      );
    },

    ingestWorkflowy: async (email, pass, link, library_id) => {
      return await apiCall(
        "POST",
        `library/ingest/workflowy`,
        {},
        {
          username: email,
          password: pass,
          url: link,
          includeMirrors: true,
          includeBacklinks: true,
          library_id: library_id,
        }
      );
    },

    getStatistics: async (namespace) => {
      return await apiCall("GET", `library/stats/${namespace}`);
    },

    refreshLibraryItem: async (folderId) => {
      return await apiCall("POST", `library/${folderId}/refresh`);
    },
    ingestSharedChats: async (projectId, libraryId) => {
      return await apiCall(
        "POST",
        `library/sharedchats/${projectId}/${libraryId}`
      );
    },
    ingestYouTubeVideo: async (videoUrl, libraryId) => {
      const token = await getToken();
      return await apiCall(
        "POST",
        "library/ingest/video",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { video_url: videoUrl, library_id: libraryId }
      );
    },
  };

  const WebsocketAPI = {
    currentChannelId: null,

    connect: async () => {
      if (
        activeChannelId === WebsocketAPI.currentChannelId ||
        !activeChannelId
      ) {
        return;
      }

      if (socketRef.current?.readyState === WebSocket.OPEN) {
        socketRef.current.close();
      }

      // Update the current channel ID
      WebsocketAPI.currentChannelId = activeChannelId;

      try {
        const token = await getToken();
        if (!token) {
          throw new Error("Failed to get authentication token");
        }

        const wsUrl = `${import.meta.env.VITE_WS_URL}/ws/${activeChannelId}?token=${token}`;
        socketRef.current = new WebSocket(wsUrl);

        socketRef.current.onopen = () => {
          console.log("WebSocket Connected");
          setIsConnected(true);
          reconnectAttemptsRef.current = 0;
        };

        socketRef.current.onclose = (event) => {
          console.log("WebSocket Disconnected", event);
          setIsConnected(false);
          WebsocketAPI.reconnect();
        };

        socketRef.current.onerror = (error) => {
          console.error("WebSocket Error:", error);
        };

        socketRef.current.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            setLastMessage(message);
            window.dispatchEvent(
              new CustomEvent("newWebSocketMessage", { detail: message })
            );
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };
      } catch (error) {
        console.error("Error setting up WebSocket:", error);
        WebsocketAPI.reconnect();
      }
    },

    reconnect: () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      const delay = Math.min(
        30000,
        Math.pow(2, reconnectAttemptsRef.current) * 1000
      );
      reconnectTimeoutRef.current = setTimeout(() => {
        console.log(
          `Attempting to reconnect... (Attempt ${reconnectAttemptsRef.current + 1})`
        );
        reconnectAttemptsRef.current++;
        WebsocketAPI.connect();
      }, delay);
    },

    sendMessage: (message) => {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        console.log("Sending WebSocket message:", message);
        socketRef.current.send(JSON.stringify(message));
      } else {
        console.error("WebSocket is not connected. Message not sent:", message);
      }
    },
  };

  const refreshAppData = async () => {
    const rsp = await apiCall("GET", "projects/");
    setProjects(rsp);
  };

  const folderStatusFinder = (folder) => {
    return folder.manifest.some((item) => item.ingestion_status === "Failed")
      ? "Failed"
      : folder.manifest.some(
        (item) =>
          item.ingestion_status === "Unknown" || !item.ingestion_status
      )
        ? "Unknown"
        : "Ingested";
  };

  const dateFinder = (folder) => {
    const latestItem = folder.manifest.reduce((latest, item) => {
      return !latest || new Date(item.updated_at) > new Date(latest.updated_at)
        ? item
        : latest;
    }, null);
    return latestItem ? latestItem.updated_at : null;
  };

  const formatDate = (date) => {
    const MM = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");

    return `${MM}/${dd} ${hh}:${mm}`;
  };

  const updateLibraryData = useCallback((newLibraryItems, force = false) => {
    const transformedData = newLibraryItems.reduce((accumulator, newLibraryItem) => {
      if (!newLibraryItem.index_reference.startsWith('sharedchats__')) {
        accumulator.push({
          id: newLibraryItem.index_reference,
          file_name: newLibraryItem.name,
          file_type: "FOLDER",
          url: `https://drive.google.com/drive/folders/${newLibraryItem.index_reference.replace("drive__", "")}`,
          updated_at: formatDate(new Date(dateFinder(newLibraryItem))),
          ingestion_status: folderStatusFinder(newLibraryItem),
          children: newLibraryItem.manifest.map(item => ({
            id: item.id,
            file_name: item.name,
            file_type: convertMimeType(item.mimeType),
            url: item.webViewLink,
            priority: item.priority,
            updated_at: formatDate(new Date(item.updated_at)),
            ingested: item.ingestion_status,
            ingestion_status: item.ingestion_status || (item.ingested ? "Ingested" : "Unknown")
          }))
        });
      }
      return accumulator;
    }, []);

      setLibraryData((prevData) =>
        force ? transformedData : [...prevData, ...transformedData]
      );

      if (transformedData.length > 0 && force) {
        setFirstFolderData(transformedData[0]);
      }
    },
    [activeProjectId, libraryId]
  );

  const fetchLibraryData = useCallback(
    async (libraryId) => {
      const response = await LibraryAPI.getLibrary(libraryId);
      console.log("libraryId: ", libraryId);
      if (response?.items?.length > 0) {
        updateLibraryData(response.items, true);
      }
    },
    [activeProjectId, libraryId, updateLibraryData]
  );

  const handleAddSource = async (url, library_id = libraryId) => {
    const rsp = await LibraryAPI.addSource(url, library_id);

    if (rsp.status !== "success") {
        throw new Error(rsp.message);
    }

    const newLibraryItem = {
      index_reference: rsp.index_reference,
      name: rsp.name,
      manifest: rsp.manifest.map((item) => ({
        id: item.id,
        mimeType: item.mimeType,
        name: item.name,
        webViewLink: item.url,
        priority: item.priority,
        updated_at: new Date(),
      })),
    };

    handleBlockPriorityUpdates();
    updateLibraryData([newLibraryItem]);
    return newLibraryItem;
  };

  const handleIngestWorkflowy = async (email, pass, link, library_id) => {
    const rsp = await LibraryAPI.ingestWorkflowy(email, pass, link, library_id);

    if (rsp.status !== "success") {
      const toastExec = rsp.message.includes(
        "Failed to ingest Workflowy content."
      )
        ? toast.success
        : toast.error;
      toastExec(rsp.message);
      return;
    }
    if (libraryData.find(item => item.id === rsp.index_reference)) {
        updateChildIngestionStatus(rsp.index_reference, rsp.name, "Added", new Date(), rsp.name, 'text/markdown');
    } else {
        updateLibraryData([{
          index_reference: rsp.index_reference,
          name: 'Project Files',
          manifest: rsp.manifest.map((item) => ({
            id: item.id,
            mimeType: item.mimeType,
            name: item.name,
            webViewLink: '',
            priority: item.priority,
            updated_at: new Date(),
            ingestion_status: "Added",
          })),
        }]);
      }
    handleBlockPriorityUpdates();
    return rsp;
  };

  const handleIngestYouTubeVideo = async (videoUrl, libraryId) => {
    const response = await LibraryAPI.ingestYouTubeVideo(videoUrl, libraryId);
    console.log("response: ", response);
    if (response.status === "success") {
      toast.success("YouTube video ingestion started");
    } else {
      toast.error(response.message || "Failed to ingest YouTube video");
    }

    if (libraryData.find(item => item.id === response.index_reference)) {   
      updateChildIngestionStatus(response.index_reference, response.name, "Added", new Date(), response.name, "text/plain");
    } else {
      updateLibraryData([{
        index_reference: response.index_reference,
        name: 'Project Files',
        manifest: response.manifest.map((item) => ({
          id: item.id,
          mimeType: item.mimeType,
          name: item.name,
          webViewLink: '',
          priority: item.priority,
          updated_at: new Date(),
          ingestion_status: "Added",
        })),
      }]);
    }

    handleBlockPriorityUpdates();
    return response;
  };

  const updateItemOnServer = useCallback(
    async (indexReference, itemId, updates) => {
      const rsp = await LibraryAPI.updateItem(indexReference, itemId, updates);

      if (rsp.error) {
        console.error(
          "Error updating item on server:",
          JSON.stringify(rsp, null, 2)
        );
        return {
          status: rsp.error.response?.status || 500,
          statusText: rsp.error.response?.statusText || "Internal Server Error",
          error: rsp.error.response?.data || "Unknown error",
        };
      }

      return rsp;
    },
    []
  );

  const handleItemUpdate = useCallback((itemId, updates) => {
    setLibraryData((prevLibrary) => {
      let indexReference;

      const newLibrary = prevLibrary.map((item) => {
        if (!item.children) return item;

        const updatedChildren = item.children.map((doc) => {
          if (doc.id !== itemId) return doc;
          indexReference = item.id;
          return { ...doc, ...updates };
        });

        return { ...item, children: updatedChildren };
      });

      if (indexReference) {
        updateItemOnServer(indexReference, itemId, updates);
      } else {
        console.error(`Could not find index_reference for itemId: ${itemId}`);
      }

      return newLibrary;
    });
  }, []);

  const deleteDriveFolder = useCallback(
    async (index_reference) => {
      const rsp = await LibraryAPI.deleteDriveFolder(
        libraryId,
        index_reference
      );
      setLibraryData((prevData) =>
        prevData.filter((item) => item.id !== index_reference)
      );
      return true;
    },
    [libraryId]
  );

  const getStats = useCallback(async (namespace) => {
    const rsp = await LibraryAPI.getStats(namespace);
    return rsp || null;
  }, []);

  const handleBlockPriorityUpdates = useCallback(() => {
    setBlockPriorityUpdates(true);
    setTimeout(() => {
      setBlockPriorityUpdates(false);
    }, 10000);
  }, []);

  const updateChildIngestionStatus = useCallback((folderId, childId, newStatus, date, newName, newMimeType) => {
    setLibraryData(prevData => {
      const newData = prevData.map(folder => {
        if (folder.id === folderId) {
          let updatedChildren = folder.children || [];

          if (newStatus === "Deleted") {
            updatedChildren = updatedChildren.filter(child => child.id !== childId);
          } else if (newStatus === "Added") {
            updatedChildren = [...updatedChildren, { id: childId, file_name: newName, file_type: convertMimeType(newMimeType), ingested: "Added", ingestion_status: "Added", updated_at: formatDate(new Date(date)) }];
          } else {
            updatedChildren = updatedChildren.map(child => {
            if (child.id === childId && newStatus !== "Processing") {
              return { ...child, ingested: newStatus, ingestion_status: newStatus, updated_at: formatDate(new Date(date)) };
            } else if (child.id === childId && newStatus === "Processing") {
              return { ...child, ingestion_status: newStatus };
            }
            return child;
          });
          }
          let newFolderStatus = "Ingested";
          if (updatedChildren.some(child => child.ingestion_status === "Processing")) {
            newFolderStatus = "Processing";
          } else if (updatedChildren.some(child => child.ingestion_status === "Failed")) {
            newFolderStatus = "Failed";
          }

          return {
            ...folder,
            children: updatedChildren,
            ingestion_status: newFolderStatus
          };
        }
        return folder;
      });

      return newData;
    });
  }, []);


  const refreshLibraryItem = useCallback(async (folderId) => {
    const rsp = await LibraryAPI.refreshLibraryItem(folderId);
    if (rsp.status !== "success") {
      throw new Error(rsp.message);
    }
  }, []);

  const streamFolderSyncStatus = async (folderId) => {
    const eventSource = new EventSource(
      `${API_URL}/library/itemstatus/${folderId}`
    );

    eventSource.onmessage = (event) => {
      const parts = event.data.split("|");
      const date = parts[1];
      const itemId = parts[0].split(":")[0];
      const status = parts[0].split(":")[1];
      const newName = parts[2] || "New File";
      const newMimeType = parts[3] || "New";

      if (["Ingested", "Incompatible", "Failed", "Processing", "Unchanged", "ProcessingInitiated", "Deleted", "Added", "Unknown","Ridiculous" ].includes(status)) {
        updateChildIngestionStatus(folderId, itemId, status, date, newName, newMimeType);
      }

      if (status === "Stop") {
        fetchLibraryData(libraryId);
        eventSource.close();
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log("EventSource connection to server opened.");
    };

    eventSource.onclose = () => {
      console.log("EventSource connection to server closed.");
    };

    return eventSource;
  };

  useEffect(() => {
    const libraryId = projects.find(
      (project) => project.project_id === activeProjectId
    )?.library_id;
    if (libraryId) {
      setLibraryId(libraryId);
      fetchLibraryData(libraryId);
    }
  }, [activeProjectId, projects]);

  useEffect(() => {
    setIsPanelVisible(false);
    setSelectedPanel(null);
    setCurrentCode("");
    setSlmLeaderboardData({});
    setChatSidebarOpen(false);
  }, [activeProjectId, activeChannelId]);

  useEffect(() => {
    console.log("import.meta.env\n", import.meta.env.VITE_WS_URL);
    const loadAppData = async () => {
      const rsp = await apiCall("GET", "projects/");

      const triggerLibraryRefresh = async () => {
        const refreshTimestamp = localStorage.getItem("refresh_timestamp");
        if (
          refreshTimestamp &&
          Date.now() - Number(refreshTimestamp) < 1200000
        ) {
          console.log("Not refreshing library: too soon");
          return;
        }
        localStorage.setItem("refresh_timestamp", Date.now().toString());
        await InteractAPI.spawnSandbox();
        console.log("Triggering refresh on all libraries, >20m");
        for (const project of rsp) {
          const libraryId = project.library_id;
          let library = await LibraryAPI.getLibrary(libraryId);
          library.items = library.items.filter((item) =>
            item.index_reference.startsWith("drive__")
          );
          library.items.forEach((item, index) => {
            setTimeout(async () => {
              console.log(`Refreshing ${item.index_reference}`);
              await LibraryAPI.refreshLibraryItem(item.index_reference);
            }, 4000 * index);
          });
        }
      };
      //await triggerLibraryRefresh();
      //setInterval(triggerLibraryRefresh, 1200000);
      setProjects(rsp);
    };

    loadAppData();

    WebsocketAPI.connect();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        apiCall,

        isLeftSidebarOpen,
        toggleLeftSidebar,
        isRightSidebarOpen,
        toggleRightSidebar,

        ProjectsAPI,
        UsersAPI,
        InteractAPI,
        ChannelsAPI,
        AIAPI,
        LibraryAPI,
        WebsocketAPI,

        refreshAppData,

        activeProjectId,
        setActiveProjectId,
        projects,
        setProjects,

        currentCode,
        setCurrentCode,
        slmLeaderboardData,
        setSlmLeaderboardData,
        isStreaming,
        setIsStreaming,
        triggerPreview,
        setTriggerPreview,
        isPanelVisible,
        setIsPanelVisible,
        selectedPanel,
        setSelectedPanel,
        chatSidebarOpen,
        setChatSidebarOpen,

        activeChannelId,
        setActiveChannelId,
        editingChannelId,
        setEditingChannelId,
        editedChannelName,
        setEditedChannelName,
        channels,
        setChannels,

        libraryData,
        setLibraryData,
        libraryId,
        setLibraryId,
        firstFolderData,
        handleAddSource,
        handleIngestWorkflowy,
        handleIngestYouTubeVideo,
        handleItemUpdate,
        deleteDriveFolder,
        getStats,
        blockPriorityUpdates,
        handleBlockPriorityUpdates,
        updateChildIngestionStatus,
        refreshLibraryItem,
        streamFolderSyncStatus,

        isConnected,
        setIsConnected,
        lastMessage,
        setLastMessage,

        socketRef,
        reconnectTimeoutRef,
        reconnectAttemptsRef,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
