import React, { useCallback, useEffect, useState, } from "react";
import { ClerkProvider, SignedIn, SignedOut, UserProfile, } from "@clerk/clerk-react";
import { Navigate, Route, BrowserRouter as Router, Routes, useParams } from "react-router-dom";

import CustomUserProfile from "./components/customUserProfile";
import Onboard from "./components/Onboard";
import LeftSidebar from "./components/LeftSidebar";
import ProjectChat from "./components/ProjectChat";
import ProjectConfig from "./components/ProjectConfig";
import ProjectCreate from "./components/ProjectCreate";
import Projects from "./components/Projects";
import RightSidebar from "./components/RightSidebar";
import SlidingPanel from "./components/SlidingPanel";
import Topbar from "./components/Topbar";
import SignInContainer from "./components/SignInContainer";
import InvitationRedirect from "./components/InvitationRedirect";

import { Toaster } from "react-hot-toast";
import { DataProvider, useData } from "./contexts/DataContext";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

const AuthenticatedApp = () => {
  const { isPanelVisible, setIsPanelVisible, selectedPanel, setSelectedPanel, toggleRightSidebar, chatSidebarOpen } = useData();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerState = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const handlePanelSelect = useCallback((panelName) => {
    setSelectedPanel(panelName);
    setIsPanelVisible(true);
    toggleRightSidebar();
  }, []);

  useEffect(() => {
    if (selectedPanel) {
      setIsPanelVisible(true);
    } else {
      const timer = setTimeout(() => setIsPanelVisible(false), 300); // Match transition duration
      return () => clearTimeout(timer);
    }
  }, [selectedPanel]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-D8NC3YF7Y7';
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-D8NC3YF7Y7');
  }, []);

  return (
    <div className="_app-layout">
      <Topbar />
      <div className={`_main-container ${chatSidebarOpen ? '_chat-sidebar-expanded' : ''}`}>
        <LeftSidebar />
        <div
          className="_content"
          style={{
            width: selectedPanel ? "calc(65% - 20px)" : "calc(100% - 40px)",
            marginRight: selectedPanel ? "10px" : "20px",
          }}
        >
          <Routes>
            <Route path="/profile" element={<CustomUserProfile />} />
            <Route path="/projects/*" element={<Projects />} />
            <Route path="/onboard" element={<Onboard />} />
            <Route path="/join/:joinId" element={<Onboard />} />
            <Route path="/projects/new" element={<ProjectCreate />} />
            <Route path="/projects/:projectId" element={<ProjectChat drawerOpen={drawerOpen} handleDrawerState={handleDrawerState} />} />
            <Route path="/projects/:projectId/chat/:channelId" element={<ProjectChat drawerOpen={drawerOpen} handleDrawerState={handleDrawerState} />} />
            <Route path="/projects/:projectId/*" element={<ProjectConfig />} />
            <Route path="/" element={<Projects />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <SlidingPanel />
        <RightSidebar onPanelSelect={handlePanelSelect} />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
        <SignedIn>
          <DataProvider>
            <AuthenticatedApp />
          </DataProvider>
        </SignedIn>
        <SignedOut>
          <div className="_app-layout">
            <Routes>
              <Route path="/join/:inviteLink" element={<InvitationRedirect />} />
              <Route path="/invite" element={<InvitationRedirect />} />
              <Route path="/" element={<SignInContainer />} />
              <Route path="*" element={<SignInContainer />} />
            </Routes>
          </div>
        </SignedOut>
        <Toaster />
      </ClerkProvider>
    </Router>
  );
};

export default App;
