
import React, { useState, useEffect, useRef } from 'react';
import { FaChevronLeft, FaCode } from "react-icons/fa";
import { LuChevronRight, LuCode2 } from 'react-icons/lu';
import { useData } from '../contexts/DataContext';

const MessageArtifactButton = ({ artifact }) => {
  const { setIsPanelVisible, setSelectedPanel, setCurrentCode, setTriggerPreview } = useData();

  const goToArtifact = () => {
    setIsPanelVisible(true);
    setSelectedPanel('artifacts');
    setCurrentCode(artifact.content || '');
    setTriggerPreview(true);
  }

  return (
    <button
      className="_artifact-button"
      onClick={goToArtifact}
    >
      <LuCode2 />
      <span>Artifact</span>
    </button>);
}

export default MessageArtifactButton;