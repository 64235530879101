import { Button, Menu } from "@headlessui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FaRegEdit, FaRegSave } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import {
  LuChevronDown,
  LuFiles,
  LuPlus,
  LuSettings2,
  LuTrash2,
  LuUser,
  LuBarChart
} from "react-icons/lu";
import { Link, useParams } from "react-router-dom";
import { useData } from "../contexts/DataContext";
import { Loader } from "./Loader";
import ProjectChatInput from "./ProjectChatInput";
import EphorLogo from "./custom-icons/EphorLogo";

const ProjectChat = () => {
  const {
    ProjectsAPI,
    ChannelsAPI,
    LibraryAPI,
    projects,
    activeProjectId,
    activeChannelId,
    setActiveProjectId,
    setActiveChannelId,
    channels,
    editingChannelId,
    setEditingChannelId,
    editedChannelName,
    setEditedChannelName,
    setChatSidebarOpen,
  } = useData();
  const { projectId, channelId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [channelState, setChannelState] = useState("private");
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [newChannelName, setNewChannelName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [libraryId, setLibraryId] = useState("");
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    channelId: null,
    channelName: "",
  });

  const fetchChannels = async () => {
    await ChannelsAPI.getChannels(activeProjectId);
  };

  const fetchProjectDetails = async () => {
    if (!activeProjectId) {
      return;
    }

    const projectData = await ProjectsAPI.readProject(activeProjectId);
    setProjectName(projectData.name);
    setLibraryId(projectData.library.library_id);
  };

  const handleAddChannel = () => {
    setIsChannelModalOpen(true);
    setNewChannelName("");
    setChannelState("private");
  };

  const handleCreateChannel = async () => {
    const newChannel = await ChannelsAPI.createChannel(projectId, {
      name: newChannelName,
      state: channelState,
    });
    setIsChannelModalOpen(false);
    navigate("/projects/" + projectId + "/chat/" + newChannel.id);
    toast.success("Chat created successfully");
  };

  const handleDeleteChannel = (channelId) => {
    const channel = channels.find((item) => item.id === channelId);
    if (!channel) return;
    setConfirmModal({ isOpen: true, channelId, channelName: channel.name });
  };

  const confirmDeleteChannel = async () => {
    const { channelId } = confirmModal;
    await ChannelsAPI.deleteChannel(projectId, channelId);
    setConfirmModal({ isOpen: false, channelId: null, channelName: "" });
    toast.success("Chat deleted successfully");
    await LibraryAPI.ingestSharedChats(projectId, libraryId)
  };

  const handleChannelNameUpdate = async (channelId) => {
    if (editedChannelName.trim() === "") {
      toast.error("Channel name cannot be empty");
      return;
    }

    const resp = await ChannelsAPI.updateChannel(projectId, channelId, {
      name: editedChannelName,
    });

    if (!resp || resp.error) return;

    toast.success("Channel name updated successfully");
  };

  // Function to render project links at the bottom
  const renderProjectLinks = () => (
    <div className="_project-links">
      <Link to={`/projects/${projectId}/settings`} className="_workspace-link">
        <LuSettings2 />
        Project Settings
      </Link>
      <Link to={`/projects/${projectId}/metrics`} className="_workspace-link">
        <LuBarChart />
        Project Metrics
      </Link>
      <Link to={`/projects/${projectId}/participants`} className="_workspace-link">
        <LuUser />
        Participants
      </Link>
      <Link to={`/projects/${projectId}/library`} className="_workspace-link">
        <LuFiles />
        Library
      </Link>
    </div>
  );

  // Menu to list all project names and update the active project
  const renderProjectMenu = () => (
    <Menu as="div" className="_workspace-menu">
      <Menu.Button className="_workspace-menu-button">
        <span>{projectName || "Loading..."}</span>
        <LuChevronDown />
      </Menu.Button>
      <Menu.Items className="_workspace-menu-items">
        {projects.map((project, i) => (
          <Menu.Item key={`${i}-${project.id}`}>
            {({ active }) => (
              <div
                className={`_workspace-menu-item ${active ? "active" : ""}`}
                onClick={() => {
                  if (project.project_id !== projectId) {
                    navigate(`/projects/${project.project_id}`)
                  }
                }}
              >
                {project.name}
              </div>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );

  useEffect(() => {
    if (projectId !== activeProjectId) {
      setActiveProjectId(projectId);
    }

    if (channelId && channelId !== activeChannelId) {
      setActiveChannelId(channelId);
    }
  }, [projectId, channelId]);

  useEffect(() => {
    (async () => {
      if (activeProjectId) {
        setIsLoading(true);

        await fetchChannels();
        await fetchProjectDetails();

        setActiveChannelId(channelId || null);

        setIsLoading(false);
      }
    })();
  }, [activeProjectId]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="_project-chat">
          <div className="_chat-sidebar">
            <div className="_chat-sidebar-toggle">
              <button onClick={() => setChatSidebarOpen(false)}>
                <IoMdCloseCircle />
              </button>
            </div>
            {renderProjectMenu()}
            <div className="_channels-list flex-grow">

              <div className="_channels-list-section">
                <div className="_add-channel" onClick={handleAddChannel}>
                  <LuPlus />
                  <span>New Chat</span>
                </div>
              </div>

              <div className="_channels-list-section">
                <div className="_chats-container">
                  <h3>Private Chats</h3>
                  {(channels || [])
                    .filter((channel) => channel.state === "private")
                    .map((channel) => (
                      <div
                        key={channel.id}
                        className={`_channel-item ${activeChannelId === channel.id ? "active" : ""}`}
                        onClick={() => navigate("/projects/" + projectId + "/chat/" + channel.id)}
                      >
                        {editingChannelId === channel.id ? (
                          <input
                            type="text"
                            className="_edit-channel-input"
                            value={editedChannelName}
                            onChange={(e) => setEditedChannelName(e.target.value)}
                            onBlur={() => handleChannelNameUpdate(channel.id)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleChannelNameUpdate(channel.id);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          <span>{channel.name}</span>
                        )}
                        {activeChannelId === channel.id && (
                          <div className="_active-channel-buttons">
                            {editingChannelId === channel.id ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleChannelNameUpdate(channel.id);
                                }}
                                className="_save-channel-name-btn"
                              >
                                <FaRegSave />
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingChannelId(channel.id);
                                  setEditedChannelName(channel.name);
                                }}
                                className="_edit-channel-name-btn"
                              >
                                <FaRegEdit />
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteChannel(channel.id);
                              }}
                              className="_delete-channel-btn"
                            >
                              <LuTrash2 />
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <div className="_channels-list-section">
                <div className="_chats-container">
                  <h3>Shared Chats</h3>
                  {(channels || [])
                    .filter((channel) => channel.state === "shared")
                    .map((channel) => (
                      <div
                        key={channel.id}
                        className={`_channel-item ${activeChannelId === channel.id ? "active" : ""}`}
                        onClick={() => navigate("/projects/" + projectId + "/chat/" + channel.id)}
                      >
                        <span>{channel.name}</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="_channels-list-section last-section">
                <div className="_chats-container">
                  <h3>Multi-User Chats</h3>
                  {(channels || [])
                    .filter((channel) => channel.state === "multi_user")
                    .map((channel) => (
                      <div
                        key={channel.id}
                        className={`_channel-item ${activeChannelId === channel.id ? "active" : ""}`}
                        onClick={() => navigate("/projects/" + projectId + "/chat/" + channel.id)}
                      >
                        <span>{channel.name}</span>
                      </div>
                    ))}
                </div>
              </div>

            </div>

            {/* Render the project links at the bottom with padding and border */}
            <div className="_project-links-container border-t">
              {renderProjectLinks()}
            </div>

          </div>

          <div className="_chat-container flex-grow">
            {activeChannelId ? (
              <ProjectChatInput />
            ) : (
              <button className="_no-channel-selected" onClick={() => setChatSidebarOpen(true)}>
                <EphorLogo />
                <p>Select a chat to start collaborating</p>
              </button>
            )}
          </div>

          {isChannelModalOpen && (
            <div className="_channel-modal">
              <div className="_channel-modal-content">
                <h2>Create New Chat</h2>
                <input
                  type="text"
                  placeholder="Chat Name"
                  value={newChannelName}
                  onChange={(e) => setNewChannelName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCreateChannel();
                    }
                  }}
                  autoFocus
                />
                <div className="_channel-modal-buttons">
                  <button onClick={() => setIsChannelModalOpen(false)}>Cancel</button>
                  <button onClick={handleCreateChannel}>Create</button>
                </div>
              </div>
            </div>
          )}

          {confirmModal.isOpen && (
            <div className="_channel-modal">
              <div className="_channel-modal-content">
                <h2>Confirm Deletion</h2>
                <p>Are you sure you want to delete the chat "{confirmModal.channelName}"?</p>
                <div className="_channel-modal-buttons">
                  <button
                    onClick={() =>
                      setConfirmModal({
                        isOpen: false,
                        channelId: null,
                        channelName: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button onClick={confirmDeleteChannel}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectChat;
