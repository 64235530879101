import { LibraryUploadCards } from "@/components/LibraryUploadCards";
import { LibrarySourcesCard } from "@/components/LibrarySourcesCard";

export default function Library() {
  return (
    <div className="flex flex-col mx-auto">
      <p className="text-gray-600 mb-6">
        Upload sources to get answers from Ephor that leverage your data.
      </p>
      <LibrarySourcesCard />
      <div className="flex flex-col mt-6 ">
        <LibraryUploadCards />
      </div>
    </div>
  );
}