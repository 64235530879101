export const scatter_graph_colors = [
  '#a246a6',
  '#d6b9fa',
  '#a6d6a2',
  '#f2e2a6',
  '#f2a6d6',
  '#a6f2f2',
  '#f2c6a6',
  '#c6a6f2',
  '#a6f2c6',
  '#f2a6c6',
  '#d6f2a6',
  '#a6c6f2',
  '#f2a6a6',
  '#a6f2a6',
  '#a6a6f2',
  '#f2d6a6',
  '#a6f2d6'
];

export const scatter_graph_stroke_colors = [
  '#822f82',
  '#b08bd1',
  '#82b082',
  '#82a2c2',
  '#c282a2',
  '#82c2c2',
  '#c29b82',
  '#9b82c2',
  '#82c29b',
  '#c2829b',
  '#b2c282',
  '#82a2c2',
  '#c28282',
  '#82c282',
  '#8282c2',
  '#c2a282',
  '#82c2a2'
];

export const model_name_map = {
    'openai-4om': 'openai 4o-mini',
    'gemini-model-flash': 'gemini 1.5 flash',
    'groq-8': 'llama 3.1 8B [groq]',
    'anthropic-haiku3' : 'anthropic haiku3',
    'groq-3': 'llama 3.2 3B [groq]',
    'groq-70':'llama 3.1 70B [groq]',
    'groq-mistral': 'mixtral 8x7b [groq]',
    'groq-gemma': 'gemma 2 9B [groq]'
};

export const model_priority = {
    'openai-4om': 3,
    'gemini-model-flash': 2,
    'groq-8': 2,
    'anthropic-haiku3': 3,
    'groq-3': 2,
    'groq-70': 2,
    'groq-mistral': 1,
    'groq-gemma': 3
}