import React, { useEffect, useState, useCallback } from "react";
import { LuSettings2, LuUser, LuFiles, LuArrowLeft, LuBarChart } from 'react-icons/lu';
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ArtifactBoard } from "./ArtifactBoard";
import ProjectConfigGeneral from "./ProjectConfigGeneral";
import ProjectConfigLibrary from "./ProjectConfigLibrary";
import ProjectConfigParticipants from "./ProjectConfigParticipants";
import { useData } from "../contexts/DataContext";
import ProjectMetrics from "./ProjectMetrics";

const menuItems = [
  {
    id: "general",
    icon: LuSettings2,
    label: "Project Settings",
    path: "settings",
    component: ProjectConfigGeneral,
  },
  {
    id: "metrics",
    icon: LuBarChart,
    label: "Project Metrics",
    path: "metrics",
    component: ProjectMetrics,
  },
  {
    id: "participants",
    icon: LuUser,
    label: "Team Members",
    path: "participants",
    component: ProjectConfigParticipants,
  },
  {
    id: "library",
    icon: LuFiles,
    label: "Library",
    path: "library",
    component: ProjectConfigLibrary,
  },
];

const ProjectConfig = () => {
  const [activeItem, setActiveItem] = useState(menuItems[0].id);
  const { projectId } = useParams();
  const { activeProjectId, setActiveProjectId } = useData();
  const location = useLocation();

  useEffect(() => {
    if (projectId && projectId !== activeProjectId) {
      setActiveProjectId(projectId);
    }
  }, [projectId]);


  const getActiveItem = () => {
    const path = location.pathname.split("/").pop();
    return menuItems.find((item) => item.path === path)?.id || "general";
  };

  // Use useEffect to update activeItem when the location changes
  useEffect(() => {
    setActiveItem(getActiveItem());
  }, [location]);

  return (
    <div className="_project-explorer">
      <div className="_project-explorer-sidebar">
        <Link
          to={`/projects/${projectId}`}
          className="_project-explorer-sidebar-item _back-to-chat"
        >
          <LuArrowLeft className="_project-explorer-sidebar-item-icon" />
          <span>Back to Chat</span>
        </Link>
        <div className="_project-explorer-sidebar-divider"></div>
        {menuItems.map((item) => (
          <Link
            key={item.id}
            to={`/projects/${projectId}/${item.path}`}
            className={`_project-explorer-sidebar-item ${activeItem === item.id ? "_active" : ""
              }`}
          >
            <item.icon className="_project-explorer-sidebar-item-icon" />
            <span>{item.label}</span>
          </Link>
        ))}
      </div>
      <div className="_project-explorer-content">
        <div className="_project-explorer-main">
          <Routes>
            <Route
              path="metrics"
              element={<ProjectMetrics projectId={projectId} />}
            />
            <Route
              path="settings"
              element={<ProjectConfigGeneral projectId={projectId} />}
            />
            <Route
              path="participants"
              element={<ProjectConfigParticipants projectId={projectId} />}
            />
            <Route
              path="library"
              element={<ProjectConfigLibrary projectId={projectId} />}
            />
            <Route
              path="artifacts"
              element={<ArtifactBoard projectId={projectId} />}
            />
            <Route
              path="*"
              element={<ProjectConfigGeneral projectId={projectId} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ProjectConfig;
