import React, { useState, useEffect } from 'react';
import { LuPlus } from "react-icons/lu";
import { Link, useNavigate } from 'react-router-dom';
import { useData } from '../contexts/DataContext';

const Projects = ({ }) => {
  const [filterText, setFilterText] = useState('');
  const { projects, isLoading } = useData();
  const navigate = useNavigate();

  const handleFilterChange = e => {
    const text = e.target.value;
    setFilterText(text);
  };

  const filteredProjects = projects?.filter((item) =>
    !filterText
      ? true
      : Object.values(item).some((value) =>
        String(value).toLowerCase().includes(filterText.toLowerCase())
      )
  );

  if (isLoading) {
    return <div>Loading projects...</div>;
  }

  return (
    <div className="_projects-container">
      <div className="_project-actions-container">
        <input
          className='_project-action-search'
          placeholder='Search Projects'
          value={filterText}
          onChange={handleFilterChange}
          type='text'
        />
        <button className="_project-action-add" onClick={() => navigate('/projects/new')}>
          <LuPlus /><span>Create Project</span>
        </button>
      </div>
      <div className="_projects-explorer hide-scrollbar">
        {filteredProjects?.map((item) =>
          <Link
            key={item.project_id}
            className='_project-card'
            to={`/projects/${item.project_id}`}
            state={{ project: item }}
          >
            <span className='_project-card-title'>{item.name}</span>
            <span className='_project-card-description'>{item.description}</span>
            {item.info && <span className='_project-card-info-string'>{item.info}</span>}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Projects;
