import { useUser } from "@clerk/clerk-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useData } from "../contexts/DataContext";
import { IoIosClose } from "react-icons/io";
import { IoChatbubblesOutline } from "react-icons/io5";
import { LuSignal } from "react-icons/lu";
import { FaChevronUp } from "react-icons/fa";
import SharpLightAttachment from "./custom-icons/SharpLightAttachment";
import ChatDropdown from "./ChatDropdown";
import ChatSwitch from "./ChatSwitch";

// Language model types
const lmTypes = {
  "Llama 405B": "sambanova-405",
  "Sonnet 3.5": "anthropic-sonnet35",
  "OpenAI 4o": "openai-4o",
  Perplexity: "perplexity_online_huge",
  "Gemini 1.5 Pro": "gemini-1.5-pro",
};

const modelOptions = Object.entries(lmTypes).map(([name, value]) => ({
  label: name,
  value: value,
}));

const ChatInput = ({
  onSendMessage,
  sendAIMessage,
  selectedModel,
  setSelectedModel,
  isSharedContext,
  setSharedContext,
  isInternetEnabled,
  setIsInternetEnabled,
  lockModelSelection,
  setLockModelSelection,
  channelState,
}) => {
  const { sendMessage, isStreaming } = useData();
  const { user } = useUser();

  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageBase64, setImageBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const [isAIEnabled, setIsAIEnabled] = useState(true);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Adjust textarea height based on content
    const textArea = inputRef.current;
    textArea.style.height = "5px";
    textArea.style.height = textArea.scrollHeight + "px";
  }, [message]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  // The toggle function, updating only the internal state
  const toggleAIEnabled = useCallback((e) => {
    const newValue = e.target.checked;
    setIsAIEnabled(newValue);
  }, []);

  useEffect(() => {
    setIsInternetEnabled(selectedModel === "perplexity_online_huge");
  }, [selectedModel]);

  const toggleInternetEnabled = () => {
    const newVal = !isInternetEnabled;
    setIsInternetEnabled(newVal);
    setLockModelSelection(newVal);
  }
  const toggleSharedContext = () => {
    setSharedContext((prev) => !prev);
  }

  const handleSubmit = useCallback(
    (e) => {
      if (e) e.preventDefault();
      if (!message.trim()) return;

      if (isAIEnabled) {
        // Use sendAIMessage function from props
        sendAIMessage(message, {
          imageFile,
          imageBase64,
          fileName,
          selectedModelOverride: selectedModel,
        });

        // Clear input fields
        setMessage("");
        setImageFile(null);
        setImageBase64("");
        setFileName("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        // Send regular message
        const messageData = {
          id: uuidv4(),
          content: message,
          timestamp: new Date().toISOString(),
          isAI: false,
          user_id: user.id,
        };
        sendMessage(messageData);
        onSendMessage(messageData);
        setMessage("");
        setImageFile(null);
        setImageBase64("");
        setFileName("");
      }
    },
    [
      isAIEnabled,
      message,
      imageFile,
      imageBase64,
      fileName,
      selectedModel,
      sendAIMessage,
      user.id,
      sendMessage,
      onSendMessage,
    ]
  );

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(file);
        setImageBase64(reader.result.split(",")[1]);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImageBase64("");
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (isInternetEnabled) {
      setSelectedModel(lmTypes["Perplexity"]);
    } else {
      setSelectedModel(lmTypes["Sonnet 3.5"]);
    }
  }, [isInternetEnabled])

  return (
    <div className="chat-input-area sticky bottom-0 p-4 rounded-md bg-white">
      <form onSubmit={handleSubmit} className="chat-input-form">
        <label className="flex items-center cursor-pointer">
          <SharpLightAttachment />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            disabled={isStreaming}
            className="hidden"
            ref={fileInputRef}
          />
        </label>
        <textarea
          className="_chat-input-area"
          ref={inputRef}
          placeholder="Enter your message..."
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          disabled={isStreaming}
        />
        <button
          className="chat-send-button"
          onClick={handleSubmit}
          disabled={isStreaming}
        >
          <FaChevronUp />
        </button>
      </form>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ChatDropdown
            options={modelOptions}
            value={selectedModel}
            onChange={(value) => setSelectedModel(value)}
            disabled={isStreaming || !isAIEnabled || lockModelSelection}
            goesUp={true}
          />
          {fileName && (
            <div className="flex items-center ml-2">
              <span>{fileName}</span>
              <button
                type="button"
                onClick={handleRemoveImage}
                className="ml-2 text-red-500 hover:text-red-700"
              >
                <IoIosClose />
              </button>
            </div>
          )}
        </div>
        <div className="_chat-switches">
          <ChatSwitch
            onToggle={toggleSharedContext}
            initialState={isSharedContext}
            onLabel="Use All Chats"
            offLabel="Limit To Current"
            icon={<IoChatbubblesOutline />}
          />
          <ChatSwitch
            onToggle={toggleInternetEnabled}
            initialState={isInternetEnabled}
            onLabel="Internet"
            offLabel="Internet"
            icon={<LuSignal />}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
