import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  FaCodeBranch,
  FaLock,
  FaShareAlt,
  FaTrophy,
  FaUsers,
} from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { v4 as uuidv4 } from "uuid";

import { useData } from "../contexts/DataContext";
import { useUser } from "@clerk/clerk-react";
import { useParams } from 'react-router-dom';

import ChatInput from "./ChatInput";

import MessageOptionsPopUp from "@/components/MessageOptionsPopUp";
import ChatDropdown from "./ChatDropdown";
import { Loader } from "./Loader";
import MessageArtifactButton from "./MessageArtifactButton";
import MessageRelatedQueries from "./MessageRelatedQueries";
import RetractableButton from "./RetractableButton";
import BrainDumpMenu from "./BrainDumpMenu";
import EphorLogo from "./custom-icons/EphorLogo";

const lmTypes = {
  "Llama 405B": "sambanova-405",
  "Sonnet 3.5": "anthropic-sonnet35",
  "OpenAI 4o": "openai-4o",
  Perplexity: "perplexity_online_huge",
  "Gemini 1.5 Pro": "gemini-1.5-pro",
};

// Define options for the sharing dropdown
const sharingOptions = [
  { value: "private", label: "Private" },
  { value: "shared", label: "Shared" },
  { value: "multi_user", label: "Multi-user" },
];

const iconList = {
  private: <FaLock />,
  shared: <FaShareAlt />,
  multi_user: <FaUsers />,
};

const ProjectChatInput = ({ }) => {
  const {
    ChannelsAPI,
    InteractAPI,
    AIAPI,
    WebsocketAPI,

    projects,
    channels,
    activeProjectId,
    activeChannelId,
    isStreaming,
    setActiveChannelId,
    setIsStreaming,
    setCurrentCode,
    LibraryAPI,
    setSelectedPanel,
    setIsPanelVisible,
    setTriggerPreview,
    isConnected,
    setSlmLeaderboardData,
    setChatSidebarOpen,
  } = useData();
  const { user, isLoaded: isUserLoaded } = useUser();
  const libraryId = projects.find(i => i.project_id === activeProjectId).library_id;
  const customInstructions = projects.find(i => i.project_id === activeProjectId).custom_instructions;
  const [channel, setChannel] = useState(null);
  const [channelState, setChannelState] = useState(channel?.state || "private");
  const [messages, setMessages] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [retryCount, setRetryCount] = useState(0);
  const [selectedModel, setSelectedModel] = useState("anthropic-sonnet35");
  const messagesEndRef = useRef(null);

  const [statusToastId, setStatusToastId] = useState(null);
  const [isSharedContext, setSharedContext] = useState(true);
  const [isInternetEnabled, setIsInternetEnabled] = useState(false);
  const [lockModelSelection, setLockModelSelection] = useState(false);

  const accumulatedCodeRef = useRef("");
  const isFirstCodeRef = useRef(true);

  const slmLeaderboardData = {};

  const isInteractive =
    channelState === "multi_user" || channel?.creator_id === user?.id;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  const sortMessagesByTimestamp = (msgs) => {
    return msgs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  };

  const handleForkChannel = useCallback(async () => {
    if (!activeChannelId || !activeProjectId) return;

    try {
      const forkedChannel = await ChannelsAPI.forkChannel(activeProjectId, activeChannelId);
      toast.success(
        `Channel forked successfully. New channel: ${forkedChannel.name}`
      );
      ChannelsAPI.getChannels(activeProjectId);
      setActiveChannelId(forkedChannel.id);
    } catch (error) {
      console.error("Failed to fork channel:", error);
      toast.error("Failed to fork channel. Please try again.");
    }
  }, [activeChannelId, activeProjectId, ChannelsAPI]);

  const handleExport = useCallback(async (exportOption, exportFormat) => {
    if (!activeChannelId || !activeProjectId) return;

    try {
      const channelId = exportOption === "channel" ? activeChannelId : null;
      const projectName = projects.find(p => p.project_id === activeProjectId)?.name || "unknown_project";
      const channelName = channelId ? (channels.find(c => c.id === channelId)?.name || "unknown_channel") : "";
      const fileExtension = exportOption === "project" ? "zip" : exportFormat;
      const fileName = `${projectName} - ${exportOption === "channel" ? channelName : exportFormat.toUpperCase()}`.trim();
      await ChannelsAPI.exportData(activeProjectId, exportOption, exportFormat, channelId, fileName, fileExtension);
      toast.success(`${exportOption.charAt(0).toUpperCase() + exportOption.slice(1)} export initiated successfully.`);
    } catch (error) {
      console.error(`Error exporting ${exportOption}:`, error);
      toast.error("Internal server error. Please try again later.");
    }
  }, [activeChannelId, activeProjectId, ChannelsAPI]);

  const fetchChannelAndMessages = useCallback(async () => {
    if (!activeChannelId || !activeProjectId) return;

    setIsLoading(true);
    setError(null);

    try {
      const channelResponse = await ChannelsAPI.getChannel(
        activeProjectId,
        activeChannelId,
      );
      const channelMessagesResponse = await ChannelsAPI.getChannelMessages(
        activeProjectId,
        activeChannelId,
      );
      setChannel(channelResponse);
      setChannelState(channelResponse.state);
      setMessages(sortMessagesByTimestamp(channelMessagesResponse));
      setRetryCount(0); // Reset retry count on successful fetch
    } catch (error) {
      console.error("Failed to fetch channel or messages:", error);
      setError("Failed to load channel data");
      if (retryCount < 3) {
        setTimeout(
          () => {
            setRetryCount((prevCount) => prevCount + 1);
          },
          1000 * (retryCount + 1)
        ); // Exponential backoff
      }
    }

    setIsLoading(false);
  }, [activeProjectId, activeChannelId]);

  const handleNewMessage = useCallback((message) => {
    setMessages((prevMessages) => {
      const existingMessage = prevMessages.find((msg) => msg.id === message.id);

      const olderThan30Seconds =
        new Date().getTime() - new Date(message.timestamp).getTime() > 30000;

      if (existingMessage && olderThan30Seconds) {
        return prevMessages;
      } else if (existingMessage) {
        // Update existing message
        return sortMessagesByTimestamp(
          prevMessages.map((msg) =>
            msg.id === message.id
              ? { ...msg, ...message, lm_type: message.lm_type || msg.lm_type }
              : msg
          )
        );
      } else {
        // Add new message
        return sortMessagesByTimestamp([...prevMessages, message]);
      }
    });
  }, []);

  const handleSendMessage = useCallback(
    async (newMessage) => {
      if (!isUserLoaded || !user) {
        console.error("User not loaded or not logged in");
        return;
      }

      const messageWithDetails = {
        id: newMessage.id,
        content: newMessage.content,
        timestamp: new Date().toISOString(),
        isAI: newMessage.isAI,
        lm_type: newMessage.lm_type,
        user_id: user.id,
        user_name: user.username || user.fullName || "Unknown User",
        user_picture: user.imageUrl || "",
        isRegenerated: newMessage.isRegenerated || false,
      };
      WebsocketAPI.sendMessage(messageWithDetails);
      handleNewMessage(messageWithDetails); // Immediately add the message to the local state
    },
    [isUserLoaded, user, handleNewMessage]
  );

  const canInteract = useCallback(() => {
    if (!channel || !user) return false;
    if (channel.creator_id === user.id) return true;
    if (channelState === "private") return false;
    if (channelState === "shared") return false;
    return channelState === "multi_user";
  }, [channel, user, channelState]);

  const MAX_CONTEXT_LENGTH = 200000;

  const transformMessages = (messages) => {
    let transformedMessages = [];
    let totalLength = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      const msg = messages[i];
      const role = msg.isAI ? "assistant" : "user";
      const code = msg.artifacts?.length > 0 ? msg.artifacts[0].content : null;
      const transformedMsg = {
        role: role,
        content: code ? msg.content + "Code Generated:\n" + code : msg.content,
      };

      const msgLength = JSON.stringify(transformedMsg).length;

      if (totalLength + msgLength > MAX_CONTEXT_LENGTH) {
        break;
      }

      transformedMessages.unshift(transformedMsg);
      totalLength += msgLength;
    }

    return transformedMessages;
  };

  const handleStreamingMessage = useCallback((streamingMessage) => {
    setMessages((prevMessages) => {
      const messageIndex = prevMessages.findIndex(
        (msg) => msg.id === streamingMessage.id
      );
      if (messageIndex !== -1) {
        // Update existing message
        const updatedMessages = [...prevMessages];
        updatedMessages[messageIndex] = {
          ...updatedMessages[messageIndex],
          ...streamingMessage,
          lm_type:
            streamingMessage.lm_type || updatedMessages[messageIndex].lm_type,
        };
        return sortMessagesByTimestamp(updatedMessages);
      } else {
        // Add new streaming message
        return [...prevMessages, streamingMessage];
      }
    });
  }, []);

  // Modified sendAIMessage function
  const sendAIMessage = useCallback(
    async (messageContent, options = {}) => {
      if (!messageContent.trim()) {
        toast.error("Input is required to use AI features");
        return;
      }

      const {
        imageFile = null,
        imageBase64 = "",
        fileName = "",
        selectedModelOverride,
        messageId = uuidv4(),
        userMessageId = uuidv4(),
        sendUserMessage = true,
        isRegenerated = false,
      } = options;
      const lm_type = selectedModelOverride || selectedModel;

      setIsStreaming(true);
      setSlmLeaderboardData({});
      let accumulatedResponse = "";

      if (sendUserMessage) {
        const userMessageData = {
          id: userMessageId,
          content: messageContent,
          timestamp: new Date().toISOString(),
          isAI: false,
          user_id: user.id,
          lm_type,
          is_image: !!imageFile,
          file_attachment: imageBase64,
        };
        handleSendMessage(userMessageData);
        WebsocketAPI.sendMessage(userMessageData);
      }

      const transformedMessages = transformMessages(messages);

      try {
        const reader = await AIAPI.streamResponse(
          messageId,
          messageContent,
          transformedMessages,
          imageBase64,
          imageFile ? fileName : null,
          lm_type,
          libraryId,
          12,
          {},
          activeProjectId,
          isSharedContext,
          customInstructions
        );
        const decoder = new TextDecoder();

        let isStreamEnded = false;
        let hasReceivedSLMData = false;

        while (!isStreamEnded) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split("\n");

          for (const line of lines) {
            if (line.startsWith("data: ")) {
              try {
                const eventData = JSON.parse(line.slice(6));
                if (eventData.type_id === "text") {
                  accumulatedResponse += eventData.chunk;
                  const streamingMessage = {
                    id: messageId,
                    content: accumulatedResponse,
                    timestamp: new Date().toISOString(),
                    isAI: true,
                    isStreaming: true,
                    user_id: "Ephor AI",
                    lm_type,
                  };
                  handleStreamingMessage(streamingMessage);
                } else if (eventData.type_id === "code") {
                  if (isFirstCodeRef.current) {
                    setSelectedPanel("artifacts");
                    setIsPanelVisible(true);
                    InteractAPI.spawnSandbox();
                    accumulatedCodeRef.current = "";
                    isFirstCodeRef.current = false;
                  }
                  accumulatedCodeRef.current += eventData.chunk;
                  setCurrentCode(accumulatedCodeRef.current);
                } else if (eventData.type_id === "status") {
                  if (statusToastId) {
                    toast.dismiss(statusToastId);
                  }
                  const newToastId = toast(eventData.chunk, {
                    duration: 5000,
                    position: "top-center",
                  });
                  setStatusToastId(newToastId);
                  if (
                    eventData.chunk.includes("React code") &&
                    eventData.chunk.includes("Completed")
                  ) {
                    setTriggerPreview(true);
                  }
                } else if (eventData.type_id === "slm_text") {
                  if (!hasReceivedSLMData && window.innerWidth >= 768) {
                    setSelectedPanel("slm_leaderboard");
                    setIsPanelVisible(true);
                  }

                  hasReceivedSLMData = true;

                  const {
                    slm_type,
                    slm_response_end = false,
                    slm_time_to_complete = -1,
                    slm_similarity_score = -1,
                  } = eventData.metadata;

                  if (!slmLeaderboardData[slm_type]) {
                    slmLeaderboardData[slm_type] = {
                      name: slm_type,
                      content: "",
                      time: -1,
                      similarity: -1,
                      is_completed: false,
                    };
                  }

                  if (slm_response_end) {
                    slmLeaderboardData[slm_type].is_completed = true;
                  }
                  if (slm_time_to_complete !== -1) {
                    slmLeaderboardData[slm_type].time = slm_time_to_complete;
                  }
                  if (slm_similarity_score !== -1) {
                    slmLeaderboardData[slm_type].similarity = slm_similarity_score;
                  }
                  if (eventData.chunk.length > 0) {
                    slmLeaderboardData[slm_type].content += eventData.chunk;
                  }

                  setSlmLeaderboardData({ ...slmLeaderboardData });
                } else if (eventData.type_id === "end" && eventData.is_final) {
                  const finishedCode = accumulatedCodeRef.current;
                  setCurrentCode(finishedCode);

                  const artifact = {
                    artifact_id: uuidv4(),
                    template: "",
                    content: finishedCode,
                  };

                  const finalMessage = {
                    id: messageId,
                    content: accumulatedResponse,
                    timestamp: new Date().toISOString(),
                    isAI: true,
                    isStreaming: false,
                    user_id: "Ephor AI",
                    user_name: "Ephor AI",
                    // Only add the artifact if it's present
                    artifacts:
                      !isFirstCodeRef.current && finishedCode ? [artifact] : [],
                    lm_type,
                    isRegenerated,
                    //Only add SLM Leaderboard if it's present
                    slm_leaderboard: JSON.stringify(slmLeaderboardData) || "{}",
                  };

                  WebsocketAPI.sendMessage(finalMessage);
                  handleStreamingMessage(finalMessage);
                  isStreamEnded = true;
                  isFirstCodeRef.current = true;
                  if (statusToastId) {
                    toast.dismiss(statusToastId);
                    setStatusToastId(null);
                  }

                  break;
                }
              } catch (error) {
                console.error("Error parsing event data:", error);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to get AI response");
      } finally {
        setIsStreaming(false);
        isFirstCodeRef.current = true;
        if (statusToastId) {
          toast.dismiss(statusToastId);
          setStatusToastId(null);
        }
      }
    },
    [
      messages,
      selectedModel,
      libraryId,
      user,
      statusToastId,
      isSharedContext,
      activeProjectId,
    ]
  );

  const shouldDisplayQueries =
    (messages.length === 0 ||
      messages[messages.length - 1].isAI ||
      messages[messages.length - 1].user_name === "Ephor AI") &&
    !isStreaming &&
    isInteractive;

  // Adjust resendAIMessage to use sendAIMessage
  const resendAIMessage = useCallback(
    (id, lm_type) => {
      console.log(
        `Resending AI message with id: ${id} and lm_type: ${lm_type}`
      );

      const messageIndex = messages.findIndex((msg) => msg.id === id);
      if (messageIndex === -1) {
        console.log(`Message with id: ${id} not found`);
        return; // Message not found
      }

      const precedingUserMessageIndex = messages
        .slice(0, messageIndex)
        .reverse()
        .findIndex((msg) => !msg.isAI && msg.user_name !== "Ephor AI");

      if (precedingUserMessageIndex === -1) {
        console.log(
          `No preceding user message found for message with id: ${id}`
        );
        return; // No preceding user message found
      }

      const precedingUserMessage =
        messages[messageIndex - precedingUserMessageIndex - 1];

      console.log(
        `Found preceding user message with id: ${precedingUserMessage.id}`
      );

      // Now, we can call sendAIMessage with the content of the precedingUserMessage
      sendAIMessage(precedingUserMessage.content, {
        selectedModelOverride: lm_type,
        messageId: id, // Use the same messageId to overwrite the AI message
        sendUserMessage: false, // Do not send the user message again
        isRegenerated: true,
      });
    },
    [messages, sendAIMessage]
  );

  const handleChannelStateChange = async (newState) => {
    if (!isUserLoaded || !user) {
      console.error("User not loaded or not logged in");
      return;
    }

    const updatedChannel = await ChannelsAPI.updateChannel(
      activeProjectId,
      activeChannelId,
      { state: newState }
    );

    setChannelState(updatedChannel.state);
    toast.success(`Chat is now ${newState.replace("_", " ")}`, {
      duration: 3000,
      position: "top-center",
    });
    await LibraryAPI.ingestSharedChats(activeProjectId, libraryId)
  };

  useEffect(() => {
    const eventListener = (event) => handleNewMessage(event.detail);
    window.addEventListener("newWebSocketMessage", eventListener);
    return () => {
      window.removeEventListener("newWebSocketMessage", eventListener);
    };
  }, [handleNewMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Reset component state when channelId changes
    (async () => {
      setChannel(null);
      setMessages([]);
      setIsLoading(true);
      setError(null);
      setRetryCount(0);

      if (isUserLoaded && activeChannelId) {
        fetchChannelAndMessages();
        await WebsocketAPI.connect();
      }
    })()
  }, [activeChannelId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    // if before today, show date and time AM / PM
    if (date.toDateString() !== today.toDateString()) {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else {
      // if today, show time AM / PM
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    }
  };

  if (!activeChannelId) return <div>Please select a channel</div>;
  if (isLoading) return <Loader />;
  if (error) {
    return (
      <div className="error-container">
        <p>Error: {error}</p>
        {retryCount < 3 && <p>Retrying... (Attempt {retryCount + 1}/3)</p>}
        <button
          onClick={() => {
            setRetryCount(0);
            fetchChannelAndMessages();
          }}
          className="retry-button"
        >
          Retry Now
        </button>
      </div>
    );
  }
  if (!channel) return <div>Channel not found</div>;
  if (!isConnected) return <div>Connecting to chat...</div>;

  return (
    <div className="project-chat-input">
      <div className="channel-header">
        <div className="_chat-sidebar-header-toggle">
          <button onClick={() => setChatSidebarOpen(true)}>
            <IoMdMenu />
          </button>
        </div>
        <h3>#{channel.name}</h3>
        <button
          onClick={handleForkChannel}
          className="fork-channel-button"
          title="Fork this channel"
        >
          <FaCodeBranch />
        </button>
        <BrainDumpMenu onExport={handleExport} />
        <ChatDropdown
          options={sharingOptions}
          value={channelState}
          onChange={handleChannelStateChange}
          iconList={iconList}
          disabled={!(user && channel.creator_id === user.id)}
        />
      </div>
      <div className="messages-container">
        <div className="messages">
          {messages.map((msg, index) => (
            <React.Fragment key={msg.id || index}>
              <div
                className={`message ${msg.isAI || msg.user_name === "Ephor AI" ? "ai-message" : ""}`}
              >
                <div className="avatar">
                  {msg.isAI || msg.user_name === "Ephor AI" ? (
                    <EphorLogo />
                  ) : msg.user_picture ? (
                    <img
                      src={msg.user_picture}
                      alt={`${msg.user_name}'s avatar`}
                      className="avatar-image rounded-full"
                    />
                  ) : (
                    <div className="default-avatar rounded-full">
                      {msg.user_name?.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="content">
                  <p className="user">
                    {msg.isAI || msg.user_name === "Ephor AI" ? (
                      <>
                        Ephor AI
                        {msg.lm_type && (
                          <span className="text-xs text-gray-500 ml-2">
                            (
                            {Object.entries(lmTypes).find(
                              ([_, value]) => value === msg.lm_type
                            )?.[0] || msg.lm_type}
                            )
                          </span>
                        )}
                      </>
                    ) : (
                      msg.user_name
                    )}
                    <span className="timestamp">
                      {formatTimestamp(msg.timestamp)}
                    </span>
                    {msg.isStreaming && (
                      <span className="text-xs text-blue-500 ml-2">
                        (typing...)
                      </span>
                    )}
                  </p>
                  <div className="text">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      language="javascript"
                    >
                      {msg.content}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
              <div className="message-options">
                {msg.user_name === "Ephor AI" &&
                  index === messages.length - 1 &&
                  isInteractive &&
                  !isStreaming && (
                    <MessageOptionsPopUp
                      messageId={msg.id}
                      lmTypes={lmTypes}
                      selectedType={
                        Object.entries(lmTypes).find(
                          ([_, value]) => value === msg.lm_type
                        )?.[0] ||
                        msg.lm_type ||
                        ""
                      }
                      onSelect={resendAIMessage}
                    />
                  )}
                {msg.user_name === "Ephor AI" &&
                  msg.slm_leaderboard &&
                  Object.keys(JSON.parse(msg.slm_leaderboard)).length > 0 && (
                    <RetractableButton
                      messageId={msg.id}
                      selectedType="Leaderboard"
                      onSelect={() => {
                        try {
                          const leaderboardData = JSON.parse(
                            msg.slm_leaderboard
                          );
                          setSelectedPanel("slm_leaderboard");
                          setSlmLeaderboardData(leaderboardData);
                        } catch (e) {
                          console.error("Failed to parse SLM leaderboard:", e);
                        }
                      }}
                      Icon={FaTrophy}
                      disabled={isStreaming}
                    />
                  )}
                {msg.artifacts && msg.artifacts.length > 0 && (
                  <MessageArtifactButton artifact={msg.artifacts[0]} />
                )}
              </div>
            </React.Fragment>
          ))}
          {shouldDisplayQueries && (
            <MessageRelatedQueries
              messages={messages}
              scrollToBottom={scrollToBottom}
              sendAIMessage={sendAIMessage}
            />
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      {canInteract() ? (
        <ChatInput
          messages={messages}
          channelId={activeChannelId}
          onSendMessage={handleSendMessage}
          projectId={activeProjectId}
          libraryId={libraryId}
          onStreamingMessage={handleStreamingMessage}
          sendAIMessage={sendAIMessage}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          channelState={channelState}
          isSharedContext={isSharedContext}
          setSharedContext={setSharedContext}
          isInternetEnabled={isInternetEnabled}
          setIsInternetEnabled={setIsInternetEnabled}
          lockModelSelection={lockModelSelection}
          setLockModelSelection={setLockModelSelection}
        />
      ) : (
        // add border radius 8 here
        <div className="_read-only-message p-4 bg-gray-100 text-center">
          This channel is {channelState === "shared" ? "read-only" : "private"}.
        </div>
      )}
    </div>
  );
};

export default ProjectChatInput;
