import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useData } from '../contexts/DataContext';
import { GoPersonAdd } from "react-icons/go";
import { FaTrash } from 'react-icons/fa'; // Import trash icon
import { toast } from 'react-hot-toast';
import { Loader } from './Loader';
import { LuClipboard, LuMail, LuUserMinus } from 'react-icons/lu';

const ProjectConfigParticipants = () => {
  const { projects, ProjectsAPI, UsersAPI, activeProjectId } = useData();
  const { user } = useUser();
  const [participants, setParticipants] = useState([]);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const initialProject = projects.find(i => i.project_id === activeProjectId);
  const [project, setProject] = useState(initialProject);

  const invitedEmails = Object.keys(project?.invitations || {});

  const handleInviteChange = (event) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleInvite = async () => {
    try {
      const response = await ProjectsAPI.addUserToProject(activeProjectId, email);

      if (response && response.user_id) {
        setProject(prevProject => ({
          ...prevProject,
          users: {
            ...prevProject.users,
            [response.user_id]: 'member'
          }
        }));

        toast.success('User added to project');
      } else if (response && response.inviteId) {
        setProject(prevProject => ({
          ...prevProject,
          invitations: {
            ...prevProject.invitations,
            [email]: response.inviteId
          }
        }));

        toast.success(`Invitation successfully sent to ${email}`);
      } else {
        toast.error('Failed to add user or send invitation');
      }
    } catch (error) {
      toast.error('Failed to add user or send invitation');
      console.error('Error adding user or sending invitation:', error);
    } finally {
      setEmail('');
    }
  };

  const isAdmin = project?.users[user.id] === 'admin';

  const handleRemoveUser = async (userId) => {
    try {
      await ProjectsAPI.removeUserFromProject(activeProjectId, userId);
      // Update project state
      setProject(prevProject => {
        const updatedUsers = { ...prevProject.users };
        delete updatedUsers[userId];
        return { ...prevProject, users: updatedUsers };
      });
      toast.success('User removed from project');
    } catch (error) {
      toast.error('Failed to remove user from project');
      console.error('Error removing user:', error);
    }
  };

  useEffect(() => {
    const initialProject = projects.find(i => i.project_id === activeProjectId);
    setProject(initialProject);
  }, [activeProjectId, projects]);

  const fetchParticipants = async () => {
    if (!project || !project.users) {
      return;
    }

    setIsLoading(true);
    const participantPromises = Object.entries(project.users).map(
      async ([userId, role]) => {
        try {
          const userProfile = await UsersAPI.getUserProfile(userId);

          return {
            ...userProfile,
            role,
            isCurrentUser: user && userId === user.id,
          };
        } catch (error) {
          console.error(`Error fetching user details for ${userId}:`, error);
          return {
            id: userId,
            name: `User ${userId.slice(0, 8)}...`,
            role: role,
            isCurrentUser: user && userId === user.id,
          };
        } finally {
          setIsLoading(false);
        }
      }
    );

    const resolvedParticipants = await Promise.all(participantPromises);
    setParticipants(resolvedParticipants);
  };

  useEffect(() => {
    fetchParticipants();
  }, [project]);

  // Generate the invite link
  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    if (project && project.invite_link_id && window) {
      setInviteLink(`${window.location.origin}/join/${project.invite_link_id}`);
    }
  }, [project]);

  // Function to copy invite link
  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    toast.success('Invite link copied to clipboard');
  };

  console.log(participants);

  return (
    <div className="_project-participants">
      <h2 className="_project-participants-title">Team Members</h2>
      <div className="_project-participants-wrapper">
        <div className="_project-participants-controls">
          <div className="_project-participants-invite">
            <input
              type="text"
              value={email}
              onChange={handleInviteChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleInvite();
                }
              }}
              placeholder="Invite a user"
            />
            <button onClick={handleInvite}>
              <GoPersonAdd />
            </button>
          </div>
          <div className="_project-participants-link">
            <label>Invite Link:</label>
            <input
              type="text"
              readOnly
              value={inviteLink}
              onClick={copyInviteLink}
            />
            <button onClick={copyInviteLink}><LuClipboard /></button>
          </div>
        </div>
        <div className="_project-participants-list">
          {isLoading && <Loader />}
          {!isLoading && participants.map((item) => (
            <div key={item.account_id} className="_project-participants-item">
              <img src={item.picture} alt={item.name} />
              <span>{item.name}</span>
              {!item.isCurrentUser && isAdmin && (
                <button onClick={() => handleRemoveUser(item.account_id)} className="_remove-user-button">
                  <LuUserMinus />
                </button>
              )}
            </div>
          ))}
          {!isLoading && invitedEmails.map((email, i) => (
            <div key={i} className="_project-participants-item _pending-invitation">
              <LuMail />
              <span>{email}<span className="_project-participants-item-status"> (Invited)</span></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectConfigParticipants;
