import React, { useState, useRef, useEffect } from "react";
import { FaDownload } from "react-icons/fa";
import "../styles/BrainDumpMenu.scss"; // Import the styles

const BrainDumpMenu = ({ onExport }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [exportTarget, setExportTarget] = useState("channel");
  const [exportFormat, setExportFormat] = useState("txt");
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const onExportClick = () => {
    if (typeof onExport === "function") {
      onExport(exportTarget.toLowerCase(), exportFormat.toLowerCase());
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="brain-dump-menu-container" ref={menuRef}>
      <button onClick={toggleMenu} className="brain-dump-button">
        <FaDownload className={`icon`} />
      </button>
      {isMenuOpen && (
        <div className="brain-dump-menu">
          <div className="export-option">
            <div className="segmented-control">
              {[
                { displayName: "Chat", value: "channel" },
                { displayName: "Project", value: "project" }
              ].map(({ displayName, value }) => (
                <button
                  key={value}
                  onClick={() => setExportTarget(value)}
                  className={`segmented-control-button ${
                    exportTarget === value ? "active-segment" : ""
                  }`}
                >
                  {displayName}
                </button>
              ))}
            </div>
          </div>
          <div className="export-option">
            <div className="segmented-control">
              {[
                { name: "Text", value: "txt" },
                { name: "JSON", value: "json" },
                { name: "XML", value: "xml" }
              ].map(({ name, value }, index) => (
                <button
                  key={value}
                  onClick={() => setExportFormat(value)}
                  className={`segmented-control-button ${
                    exportFormat === value || (index === 0 && exportFormat === "txt") ? "active-segment" : ""
                  }`}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
          <button onClick={onExportClick} className="export-button">
            Download Chat(s)
          </button>
        </div>
      )}
    </div>
  );
};

export default BrainDumpMenu;