import { GoogleDrivePicker } from "@/components/GoogleDrivePicker";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DropZoneCard } from "@/components/ui/DropZoneCard";
import { Input } from "@/components/ui/input";
import { useData } from "@/contexts/DataContext";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { FaYoutube } from "react-icons/fa";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import  { toast }  from "react-hot-toast";

export function LibraryUploadCards({
  className,
  isDrawer = false,
}) {
  const [loading, setLoading] = useState(false);
  const [ytloading, setYtLoading] = useState(false);
  const [wfloading, setWfLoading] = useState(false);

  const navigate = useNavigate();

  const {
    handleAddSource,
    streamFolderSyncStatus,
    handleIngestWorkflowy,
    handleIngestYouTubeVideo,
    libraryId,
  } = useData();
  const [selectedFile, setSelectedFile] = useState(null);
  const [driveUrl, setDriveUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [nodeUrl, setNodeUrl] = useState("");
  const { signOut } = useClerk()

  const handleDriveUrlSubmit = async (e) => {
    e.preventDefault();
    if (driveUrl) {
      setLoading(true);
      try {
        const newItem = await handleAddSource(driveUrl);

        if (newItem) {
          setDriveUrl("");
          streamFolderSyncStatus(newItem.index_reference);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("DriveUrlSubmit Error", error.message);
        if (error.message.includes("api.clerk") || error.message.includes("oauth2.googleapis.com") || error.message.includes("refresh_token")) {
          const url = new URL(window.location.href).pathname;
          console.log("Redirecting to sign in page");
          await signOut({ redirectUrl: `/?redirect=${true}&from=${url}` });
          //navigate(`/profile?redirect=${true}&from=${url}`);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const handleYoutubeUrlSubmit = async (e) => {
    e.preventDefault();
    if (youtubeUrl) {
      setYtLoading(true);
      try {
        const response = await handleIngestYouTubeVideo(youtubeUrl, libraryId);
        if (response) {
          setYoutubeUrl("");
          // if (response.index_reference) {
          //   streamFolderSyncStatus(response.index_reference);
          // }
        }
      } catch (error) {
        console.log("Error ingesting YouTube video:", error.message);
      } finally {
        setYtLoading(false);
      }
    }
  };

  const handleWorkflowyUrlSubmit = async (e) => {
    e.preventDefault();
    setWfLoading(true);
    try {
      await handleIngestWorkflowy(username, password, nodeUrl, libraryId);
      setUsername("");
      setPassword("");
      setNodeUrl("");
      console.log("Scraping event triggered successfully");
    } catch (error) {
      console.log("Error triggering the backend event:", error);
    } finally {
      setWfLoading(false);
    }
  };

  const onFolderSelect = async (folderId) => {
    if (folderId) {
      const url = `https://drive.google.com/drive/folders/${folderId}?usp=sharing`;
      setLoading(true);
      try {
        const newItem = await handleAddSource(url);
        streamFolderSyncStatus(newItem.index_reference);
        if (newItem) {
          setDriveUrl("");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className={cn("grid items-start gap-4", className)}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
        <Card>
          <CardHeader className={isDrawer ? "pt-1" : ""}>
            {!isDrawer && (
              <CardTitle>
                <div className="flex items-center">
                  Google Drive
                  {loading && (
                    <Loader2 className="ml-2 text-gray-400 animate-spin" />
                  )}
                </div>
              </CardTitle>
            )}
          </CardHeader>
          <CardContent>
            <div className="flex items-center">
              <GoogleDrivePicker
                onFolderSelect={onFolderSelect}
              />
            </div>
            <form className="flex items-center" onSubmit={handleDriveUrlSubmit}>
              <Input
                type="text"
                placeholder="Paste link to g-drive folder"
                className="w-full mr-2"
                value={driveUrl}
                onChange={(e) => setDriveUrl(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleDriveUrlSubmit(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="outline"
                size="icon"
                rounded="full"
              >
                <PiPaperPlaneTiltBold size={15} />
              </Button>
            </form>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className={isDrawer ? "pt-1" : ""}>
            {!isDrawer && (
              <CardTitle>
                <div className="flex items-center">
                  YouTube
                  {ytloading && (
                    <Loader2 className="ml-2 text-gray-400 animate-spin" />
                  )}
                </div>
              </CardTitle>
            )}
          </CardHeader>
          <CardContent>
            <form
              className="flex items-center"
              onSubmit={handleYoutubeUrlSubmit}
            >
              <Input
                type="text"
                placeholder="Paste YouTube video URL"
                className="w-full mr-2"
                value={youtubeUrl}
                onChange={(e) => setYoutubeUrl(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleYoutubeUrlSubmit(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="outline"
                size="icon"
                rounded="full"
              >
                <FaYoutube size={15} />
              </Button>
            </form>
            <p className="mt-2 text-sm text-muted-foreground p-8">
              The uploaded video will be transcribed for use as context.
            </p>
          </CardContent>
        </Card>
        <Card className="relative">
          <CardHeader className={isDrawer ? "pt-1" : ""}>
            {!isDrawer && (
              <CardTitle>
                <div className="flex items-center">
                  WorkFlowy  <span className="text-xs text-muted-foreground px-1">(Beta)</span>
                  {wfloading && (
                    <Loader2 className="ml-2 text-gray-400 animate-spin" />
                  )}
                </div>
              </CardTitle>
            )}
          </CardHeader>
          <CardContent>
            <form id="scrape-form" onSubmit={handleWorkflowyUrlSubmit}>
              <Input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="w-full mb-2"
                placeholder="Username"
              />
              <Input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full mb-2"
                placeholder="Password"
              />
              <Input
                type="text"
                id="node-url"
                name="node-url"
                value={nodeUrl}
                onChange={(e) => setNodeUrl(e.target.value)}
                required
                className="w-full mb-2"
                placeholder="Node Internal Link"
              />
              <Button
                type="submit"
                variant="outline"
                className="w-full"
                disabled={loading}
              >
                {loading ? "Loading..." : "Add WorkFlowy Node"}
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* <DropZoneCard
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
        <Card className="relative">
          <div className="absolute inset-0 bg-gray-200 bg-opacity-50 z-10" />
          <CardHeader className={isDrawer ? "pt-1 pb-2" : "pb-2"}>
            {!isDrawer && <CardTitle>Paste</CardTitle>}
          </CardHeader>
          <CardContent>
            <Input
              type="url"
              placeholder="Enter website URL"
              className="w-full mb-2"
              disabled
            />
            <Input
              type="text"
              placeholder="Paste from your clipboard"
              className="w-full"
              disabled
            />
            <p className="mt-2 text-sm text-muted-foreground text-center">
              Feature coming soon
            </p>
          </CardContent>
        </Card> */}
      </div>
    </div>
  );
}
