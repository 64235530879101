import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../contexts/DataContext';
import { LuCodesandbox, LuPlus } from "react-icons/lu";

const Sidebar = () => {
  const { projects, activeProjectId, setActiveProjectId, setActiveChannelId } = useData();
  const navigate = useNavigate();

  const navigationOptions = [
    { label: 'Projects', path: '/projects', icon: <LuCodesandbox />, },
  ];

  const switchProject = (projectId) => {
    setActiveProjectId(projectId);
    setActiveChannelId(null);
    navigate(`projects/${projectId}`);
  }

  return (
    <div className='_sidebar'>
      <div className='_sidebar-nav'>
        {navigationOptions?.map((option, index) => (
          <React.Fragment key={index}>
            <button onClick={() => navigate(option.path)} className='_sidebar-option'>
              {option.icon}
              <span>{option.label}</span>
            </button>
            {index === 0 && <div className='_sidebar-divider'></div>}
          </React.Fragment>
        ))}
      </div>
      <div className='_sidebar-projects'>
        <div className='_sidebar-projects-list'>
          {projects && projects.map((project, index) => (
            <button
              key={index}
              className={`_sidebar-project-item ${project.project_id === activeProjectId ? 'active' : ''}`}
              onClick={() => switchProject(project.project_id)}
            >
              {project.name}
            </button>
          ))}
          <button className="_project-action-add" onClick={() => navigate('/projects/new')}>
            <LuPlus /><span>Create Project</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;