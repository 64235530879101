import React, { useEffect, useRef } from 'react';
import { useData } from '../contexts/DataContext';
import CodePreview from './Panel_CodePreview';
import SlmLeaderboard from './Panel_SlmLeaderboard';

const SlidingPanel = () => {
  const { selectedPanel, isPanelVisible } = useData();
  const panelRef = useRef(null);
  const resizerRef = useRef(null);
  const isDraggingRef = useRef(false);

  useEffect(() => {
    const panel = panelRef.current;
    const resizer = resizerRef.current;
    let startX, startWidth;

    const onMouseMove = (e) => {
      if (!panel || !isDraggingRef.current) return;
      const dx = startX - e.clientX;
      panel.style.width = `${startWidth + dx}px`;
    };

    const onMouseUp = () => {
      isDraggingRef.current = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (e) => {
      // Check if it's the primary mouse button (usually the left button)
      if (e.button !== 0) return;
      
      isDraggingRef.current = true;
      startX = e.clientX;
      startWidth = panel.getBoundingClientRect().width;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    resizer.addEventListener('mousedown', onMouseDown);

    return () => {
      resizer.removeEventListener('mousedown', onMouseDown);
      // Cleanup any lingering event listeners
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  return (
    <div
      ref={panelRef}
      className={`_sliding-panel ${selectedPanel ? '' : '_sliding-panel-hidden'}`}
    >
      <div ref={resizerRef} className='_sliding-panel-content-resize'></div>
      {isPanelVisible && (
        <div className="_sliding-panel-content">
          {selectedPanel === 'artifacts' && (
            <CodePreview />
          )}
          {selectedPanel === 'slm_leaderboard' && (
            <SlmLeaderboard />
          )}
        </div>
      )}
    </div>
  );
};

export default SlidingPanel;